export default class Email {
  digest!: string | null;
  charset!: string | null;
  date!: any | null;
  messageId!: string | null;
  sender!: string | null;
  senderName!: string | null;
  senderEmail!: string | null;
  replyTo!: string[] | null;
  replyToEmail!: string[] | null;
  from!: string[] | null;
  fromEmail!: string[] | null;
  fromName!: string[] | null;
  header!: string[] | null;
  headers!: Map<string, string[]> | null;
  recipient!: string[] | null;
  to!: string[] | null;
  toEmail!: string[] | null;
  cc!: string[] | null;
  ccEmail!: string[] | null;
  subject!: string | null;
  content!: string | null;
  threadId!: string | null;
  attachment!: string[] | null;
  hasAttachment!: boolean | null;
  isSpam!: boolean | null;
  score!: number | null;
  bcc!: string[] | null;
  textBodies!: string[] | null;
  htmlBodies!: string[] | null;
  rawEmail!: string | null;
  digestVerified!: boolean | null;
  query!: string | null;

  constructor() {
    this.digest = null;
    this.charset = null;
    this.date = null;
    this.messageId = null;
    this.sender = null;
    this.senderName = null;
    this.senderEmail = null;
    this.replyTo = null;
    this.replyToEmail = null;
    this.from = null;
    this.fromEmail = null;
    this.fromName = null;
    this.header = null;
    this.headers = null;
    this.recipient = null;
    this.to = null;
    this.toEmail = null;
    this.cc = null;
    this.ccEmail = null;
    this.subject = null;
    this.content = null;
    this.threadId = null;
    this.attachment = null;
    this.hasAttachment = null;
    this.isSpam = null;
    this.score = null;
    this.bcc = null;
    this.textBodies = null;
    this.htmlBodies = null;
    this.rawEmail = null;
    this.digestVerified = null;
    this.query = null;
  }
}
