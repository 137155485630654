<template>
  <form class="d-flex flex-column bg-white mr-xl-2 ml-2 ml-xl-0 p-4" v-on:submit.prevent>
    <AnimatedInput v-if="!advanced" v-model="all" :label="i18n.$gettext('Search term') + ':'" :inline="false"></AnimatedInput>
    <div v-else>
      <Datepicker v-if="advanced" v-model="start" :label="i18n.$gettext('From Date') + ':'" :placeholder="i18n.$gettext('Select Date')"/>
      <Datepicker v-if="advanced" v-model="end" :label="i18n.$gettext('To Date') + ':'" :placeholder="i18n.$gettext('Select Date')"/>
      <div v-for="(rule, i) in rules">
        <div class="row mt-1">
          <hr class="divider pb-2 ml-3 mr-3" style="height: initial">
          <div class="col-2">
            <button class="btn btn-outline-danger btn-raised text-left" type="button" @click="rules.splice(i, 1)">
              <i class="fas fa-trash"></i>
            </button>
          </div>
          <div class="col-4" v-if="i > 0">
            <MultiSelect v-model="rule.prefix" :options="prefixOptions" :canDeselect="true"/>
          </div>
          <div :class="i > 0 ? 'col-6' : 'col-10'">
            <MultiSelect v-model="rule.field" :options="fieldOptions" :disabled="!rule.prefix" :canDeselect="false" :placeholder="i18n.$gettext('Field')" maxHeight="240"/>
          </div>
          <div v-if="rule.field === 'HEADER'" class="col-4 mt-3">
            <AnimatedInput v-model="rule.name" :placeholder="i18n.$gettext('Header Name')"/>
          </div>
          <div :class="rule.field === 'HEADER' ? 'col-4' : 'col-5'" class="mt-3">
            <MultiSelect v-model="rule.operator" :options="operatorOptions" :disabled="rule.field === 'HEADER' ? !rule.name : !rule.field" :canDeselect="false"
                         :placeholder="rule.field === 'HEADER' ? i18n.$gettext('Cond.') : i18n.$gettext('Condition')" maxHeight="240"/>
          </div>
          <div :class="rule.field === 'HEADER' ? 'col-4' : 'col-7'" class="mt-3">
            <AnimatedInput v-model="rule.value" :disabled="!rule.operator" :placeholder="i18n.$gettext('Value')"/>
          </div>
        </div>
      </div>
      <button class="btn btn-outline-primary btn-raised text-left mt-4 mb-4" type="button" :disabled="invalidRules" @click="addRule">
        <i class="fas fa-plus"></i>
        <span class="d-none d-xl-inline-block">&emsp;<translate>Add new rule</translate></span>
        <span class="d-xl-none">&emsp;<translate>Add rule</translate></span>
      </button>
      <Checkbox v-if="advanced" v-model="withAttachment"><translate>Messages with attachments only</translate></Checkbox>
      <Checkbox v-if="advanced" v-model="includeSpam"><translate>Include spam messages</translate></Checkbox>
    </div>
    <!--<AnimatedInput v-if="advanced" v-model="content" :label="i18n.$gettext('Text (body or attachment)') + ':'" :inline="false"></AnimatedInput>
    <AnimatedInput v-if="advanced" v-model="from" :label="i18n.$gettext('Sender') + ':'" :inline="false"></AnimatedInput>
    <AnimatedInput v-if="advanced" v-model="to" :label="i18n.$gettext('Recipient (To / Bc / Bcc)') + ':'" :inline="false"></AnimatedInput>
    <AnimatedInput v-if="advanced" v-model="subject" :label="i18n.$gettext('Subject') + ':'" :inline="false"></AnimatedInput>
    <AnimatedInput v-if="advanced" v-model="attachment" :label="i18n.$gettext('Attachment (filename or metadata)') + ':'" :inline="false"></AnimatedInput>-->

    <LoadingButton type="submit" class="btn-primary btn-lg btn-raised btn-block mt-4" :action="queryEmails" :disabled="advanced && invalidRules"><i class="fa fa-search ml-1 mr-2"></i><translate>Query</translate></LoadingButton>
    <div class="text-center mt-4">
      <div class="row">
        <div class="col-12 col-md-4">
          <a class="animated" href="javascript:" @click="advanced = !advanced">
            {{ advanced ? i18n.$gettext('Simple Query') : i18n.$gettext('Advanced Search') }}
          </a>
        </div>
        <div class="col-12 col-md-4">
          <a class="animated" href="javascript:" @click="saveQueryModal.toggle">
            {{ i18n.$gettext('Save Query') }}
          </a>
        </div>
        <div class="col-12 col-md-4">
          <a class="animated" href="javascript:" @click="loadQueryModal.toggle">
            {{ i18n.$gettext('Load Query') }}
          </a>
        </div>
      </div>
    </div>
    <Modal ref="saveQueryModal" size="lg">
      <template v-slot:title>
        <h5 class="modal-title"><translate>Save query</translate></h5>
      </template>
      <template v-slot:body>
        <p class="text-dark"><translate>Please enter a name / description for this query:</translate></p>
        <AnimatedInput class="w-100 mb-4" v-model="savedQueryName"></AnimatedInput>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click="saveQueryModal.toggle"><translate>Close</translate></button>
        <LoadingButton class="btn btn-success" :action="saveQuery" :disabled="!savedQueryName"><translate>Save</translate></LoadingButton>
      </template>
    </Modal>
    <Modal ref="loadQueryModal" size="lg">
      <template v-slot:title>
        <h5 class="modal-title"><translate>Load query</translate></h5>
      </template>
      <template v-slot:body>
        <p v-if="savedQueries.call?.loading"><translate>Loading saved queries...</translate></p>
        <p v-else-if="!savedQueries.data || savedQueries.data.length === 0"><translate>No saved queries.</translate></p>
        <div v-else v-for="query in savedQueries.data" class="row mb-4">
          <div class="col">
            <p class="mb-0" style="word-break: break-word">{{ query.savedName }}</p>
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-outline-primary" @click="loadQuery(query.id)">
              <i class="fas fa-reply"></i><span class="d-none d-xl-inline-block">&emsp;<translate>load</translate></span>
            </button>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click="loadQueryModal.toggle"><translate>Close</translate></button>
      </template>
    </Modal>
  </form>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Query from "@/model/Query"
import {mailServiceApi} from "@/api/MailServiceApi"
import {queryServiceApi} from "@/api/QueryServiceApi";
import {useToast} from "vue-toastification";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import Checkbox from "@/components/common/Checkbox.vue";
import Datepicker from "@/components/common/DatePicker.vue";
import Rule from "@/model/Rule";
import Modal from "@/components/common/Modal.vue";
import {ref} from "vue";
import MultiSelect from "@/components/common/MultiSelect.vue";
import SWR from "@/api/SWR";

@Options({
  components: {
    AnimatedInput, LoadingButton, Checkbox, Datepicker, MultiSelect, Modal
  },
  props: {
    state: Object
  }
})
export default class SearchMask extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }

  //@ts-ignore
  saveQueryModal: Modal = ref<Modal | null>(null)

  //@ts-ignore
  loadQueryModal: Modal = ref<Modal | null>(null)

  savedQueryName: string = ''

  all: string = ''
  from: string = ''
  to: string = ''
  subject: string = ''
  content: string = ''
  attachment: string = ''
  start: Date | null = null
  end: Date | null = null
  withAttachment: boolean = false
  includeSpam: boolean = false
  advanced: boolean = false

  rules: Rule[] = []

  prefixOptions: { value: string, label: string }[] = [
    { value: 'AND', label: this.i18n.$gettext('AND') },
    { value: 'OR', label: this.i18n.$gettext('OR') }
  ]
  fieldOptions: { value: string, label: string }[] = [
    { value: 'TEXT', label: this.i18n.$gettext('Text (body or attachment)') },
    { value: 'SENDER', label: this.i18n.$gettext('Sender') },
    { value: 'RECEIVER', label: this.i18n.$gettext('Recipient (To / Cc / Bcc)') },
    { value: 'TO', label: this.i18n.$gettext('Recipient (To)') },
    { value: 'CC', label: this.i18n.$gettext('Recipient (Cc)') },
    { value: 'SUBJECT', label: this.i18n.$gettext('Subject') },
    { value: 'ATTACHMENT', label: this.i18n.$gettext('Attachment (filename or metadata)') },
    { value: 'HEADER', label: this.i18n.$gettext('Message Header') }
  ]
  operatorOptions: { value: string, label: string }[] = [
    { value: 'CONTAINS_ONE', label: this.i18n.$gettext('contains one') },
    { value: 'CONTAINS_ALL', label: this.i18n.$gettext('contains all') },
    { value: 'CONTAINS_NONE', label: this.i18n.$gettext('contains none') }
  ]

  get query(): Query {
    let query: Query = new Query()
    if (this.advanced) {
      query.content = this.content
      query.from = this.from
      query.to = this.to
      query.subject = this.subject
      query.attachment = this.attachment
      query.start = this.start
      query.end = this.end
      query.withAttachment = this.withAttachment
      query.includeSpam = this.includeSpam
      query.rules = this.rules
    } else {
      query.all = this.all
    }
    return query
  }

  queryEmails() {
    queryServiceApi._startQuery(this.query).then((queryId: string) => {
      mailServiceApi.resetState()
      Object.assign(this.state, { queryId: queryId, showSearch: false })
    }).catch(e => {
      this.toast.error(this.i18n.$gettext('Failed to start query.'))
    })
  }

  saveQuery() {
    let query: Query = this.query
    query.savedName = this.savedQueryName
    queryServiceApi._saveQuery(query).then(() => {
      this.toast.success(this.i18n.$gettext('The query has been saved.'))
      this.savedQueryName = ''
      this.saveQueryModal.toggle()
    }).catch(e => {
      this.toast.error(this.i18n.$gettext('Failed to save query.'))
    })
  }

  get savedQueries(): SWR<Query[], string[]> {
    return queryServiceApi.getSavedQueries([ 'created:desc' ])
  }

  loadQuery(id: string) {
    let query: Query | undefined = queryServiceApi.getQuery(id)
    if (query) {
      this.content = query.content || ''
      this.from = query.from || ''
      this.to = query.to || ''
      this.subject = query.subject || ''
      this.attachment = query.attachment || ''
      this.start = query.start ? new Date(query.start) : null
      this.end = query.end ? new Date(query.end) : null
      this.withAttachment = query.withAttachment || false
      this.includeSpam = query.includeSpam || false
      this.rules = query.rules || []
      this.all = query.all || ''
      this.advanced = Boolean(query.content || query.from || query.to || query.subject || query.attachment ||
          query.start || query.end || query.withAttachment || query.includeSpam || (query.rules && query.rules.length > 0))
    }
    this.loadQueryModal.toggle()
  }

  addRule() {
    let rule: Rule = new Rule()
    rule.prefix = 'AND'
    this.rules.push(rule)
  }

  get invalidRules(): boolean {
    return !!this.rules.find(rule => !(rule.prefix && rule.field && rule.operator))
  }
}
</script>

<style lang="scss">

</style>
