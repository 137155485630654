<template>
  <div class="container-fluid">
    <div class="row align-items-center justify-content-around" style="min-height: 100vh;">
      <div class="col-12 col-lg-6">
        <div class="card with-circle p-sm-4 p-md-6 shadow-row-element">
          <div class="card-img-circle">
            <img v-if="loading" src="../assets/ELLY_Logo_animated_loop_1_fast.svg">
            <img v-else src="../assets/ELLY_Logo_als_Pfad.svg">
          </div>
          <form class="card-body" v-on:submit.prevent>
            <div class="row">
              <div class="col mt-4">
                <h1 class="mb-2"><translate>Uniki Mail Archive</translate></h1>
                <h5 class="text-muted"><translate>Welcome! Please log in.</translate></h5>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <AnimatedInput id="login" :label="i18n.$gettext('Username')" v-model.lazy.trim="username" name="username" type="text" @autofill="handleAutofill" autocomplete="true"></AnimatedInput>
                <AnimatedInput id="password" :label="i18n.$gettext('Password')" v-model.lazy.trim="password" name="password" type="password" @autofill="handleAutofill" autocomplete="true"></AnimatedInput>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-auto">
                <LoadingButton id="signin" :action="login" type="submit" class="btn btn-outline-primary"><translate>Login</translate></LoadingButton>
              </div>
              <!--<div class="col d-flex">
                <a href="/password/forgotten" class="align-self-center"><translate>I've forgotten my password</translate></a>
              </div>-->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import AnimatedInput from "@/components/common/AnimatedInput.vue";
import LoadingButton from "@/components/common/LoadingButton.vue";
import {rpcClient} from "@/api/Vue3WebsocketClient";
import { useToast } from "vue-toastification"

@Options({
  components: {
    AnimatedInput, LoadingButton
  }
})
export default class LoginForm extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  username: string = "";
  password: string = "";
  loading: boolean = false;
  autofillOverwrite: boolean = false; //Overrides validation if chrome autofill does input something directly after page load. See: https://bugs.chromium.org/p/chromium/issues/detail?id=669724

  login () {
    this.loading = true
    rpcClient.login(this.username, this.password).catch(e => {
      this.toast.error(this.i18n.$gettext('Login failed.'))
    }).finally(() => {
      this.loading = false
    })
  }

  handleAutofill(args: boolean) {
    this.autofillOverwrite = args;
  }
}
</script>

<style lang="scss">

</style>
