export default class AuditEntry {
  id!: number | null;
  userName!: string | null;
  operation!: string | null;
  targetId!: string | null;
  entryValue!: string | null;
  entryDate!: any | null;

  constructor() {
    this.id = null;
    this.userName = null;
    this.operation = null;
    this.targetId = null;
    this.entryValue = null;
    this.entryDate = null;
  }
}
