import GeneratedMailServiceApi from "./generated/GeneratedMailServiceApi"
import {reactive} from "vue";
import {emailStore} from "@/store/generated/GeneratedEmailStore";
import Email from "@/model/Email";

class MailServiceApi extends GeneratedMailServiceApi {

    state: { total: number | null } = reactive({
        total: null
    })

    resetState(): void {
        emailStore.state.emails = new Array<Email>()
    }
}

export const mailServiceApi = new MailServiceApi()
