import GeneratedQueryServiceApi from "./generated/GeneratedQueryServiceApi"
import SWR from "@/api/SWR";
import Query from "@/model/Query";

class QueryServiceApi extends GeneratedQueryServiceApi {
    getSavedQueries(sortBy?: string[] | string, refresh?: boolean | number): SWR<Query[], string[]> {
        const swr: SWR<Query[], string[]> = super.getSavedQueries(sortBy, refresh)
        if (swr.data) swr.data = swr.data.filter(query => !!query.savedName)
        return swr
    }
}

export const queryServiceApi = new QueryServiceApi()
