

import Datepicker from 'vue3-datepicker'
import {Options, Vue} from "vue-class-component";
import {Watch} from "vue-property-decorator";
import {ref} from "vue";

@Options({
  props: {
    label: String,
    placeholder: String,
    modelValue: Date,
    inline: Boolean,
    autocomplete: String,
    error: String,
    type: String,
    disabled: Boolean,
    name: String
  },
  components: {
    Datepicker
  }
})
export default class DatePicker extends Vue {

  label!: string;
  placeholder!: string;
  modelValue!: Date;
  inline!: boolean;
  autocomplete!: string;
  error!: string;
  type!: string;
  disabled!: boolean;
  name!: string;

  focused: boolean = false;
  internalValue: Date | null = this.modelValue || null;

  //@ts-ignore
  myinput: HTMLInputElement = ref<HTMLInputElement | null>(null);

  get animationClass (): string {
    if (this.focused || (this.internalValue)) {
      return 'ai-filled' + this.errorClass;
    } else {
      return '' + this.errorClass;
    }
  }

  get labelClass (): string {
    return (this.inline ? "input-label-inline" : "input-label");
  }

  get errorClass(): string {
    if (this.error) {
      if (this.focused) {
        return ' border-danger';
      } else {
        return ' border-danger danger';
      }
    } else {
      return '';
    }
  }

  @Watch('internalValue')
  handleInput (val: Date, oldVal: Date) {
    this.$emit('update:modelValue', this.internalValue);
  }

  handleFocus () {
    this.focused = true;
    this.$emit('focus');
  }

  handleBlur () {
    this.focused = false;
    this.$emit('blur');
  }

  @Watch('modelValue')
  onValueChanged (val: Date, oldVal: Date) {
    this.internalValue = val;
  }
}
