import GeneratedSettingsServiceApi from "./generated/GeneratedSettingsServiceApi"
import Setting from "@/model/Setting";
import SWR from "@/api/SWR";

class SettingsServiceApi extends GeneratedSettingsServiceApi {

    loading: boolean = false

    defaultSettings: Setting[] = [
        { key: 'DISABLE_PERMISSION_MANAGEMENT', value: 'false' },
        { key: 'RETENTION_PERIOD', value: '' },
        { key: 'SENDER_DISPLAY', value: 'full' },
        { key: 'RECIPIENT_DISPLAY', value: 'full' },
        { key: 'RESULT_ADDRESS_DISPLAY', value: 'condensed' },
        { key: 'DETAIL_ADDRESS_DISPLAY', value: 'seperate' },
        { key: 'SPAM_THRESHOLD', value: '2' },
        { key: 'SHOW_IMAGES', value: 'false' }
    ]

    get settings(): Setting[] {
        let swr: SWR<Setting[], string[]> = settingsServiceApi.getSettings()
        if (swr.call && swr.call.promise) {
            this.loading = swr.call.loading
            swr.call.promise.finally(() => {
                this.loading = false
            })
        }
        if (swr.data) {
            const data = swr.data;
            return this.defaultSettings.map(def => data.find(setting => setting.key == def.key) || def);
        } else {
            return this.defaultSettings;
        }
    }
}

export const settingsServiceApi = new SettingsServiceApi()
