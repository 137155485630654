import { reactive } from 'vue'
import User from '@/model/User';

class GeneratedUserStore {

    state = reactive({
        users: new Array<User>()
    })

    addOrReplaceUser(newUser: User) {
        let oldUser = this.state.users.find((value: User) => {
            return value.userName === newUser.userName
        })

        if (oldUser !== undefined) {
            this.state.users = this.state.users.map((value: User) => {
                if (value.userName === newUser.userName) {
                    return newUser
                } else {
                    return value
                }
            })
        } else {
            this.state.users.push(newUser)
        }
    }
    
    addOrReplaceUsers(newUsers: User[]) {
        let newState: User[] = this.state.users.map((oldUser: User) => {
            let newUserIndex: number = -1
            for (let i = 0; i < newUsers.length; i++) {
                if (newUsers[i].userName === oldUser.userName) {
                    newUserIndex = i
                    break
                }
            }
            let user: User
            if (newUserIndex !== -1) {
                user = newUsers[newUserIndex]
                newUsers.splice(newUserIndex, 1)
            } else {
                user = oldUser
            }
            return user
        })
        for (let i = 0; i < newUsers.length; i++) {
            newState.push(newUsers[i])
        }
        this.state.users = newState
    }

    replaceUsers(users: User[]) {
        this.state.users = users
    }

    removeUser(userName: string) {
        let toDelete = this.state.users.find((user: User) => {
            return user.userName === userName
        })

        if (toDelete !== undefined) {
            let index = this.state.users.indexOf(toDelete)
            if (index > -1) {
                this.state.users.splice(index, 1)
            }
        }
    }
}

export const userStore = new GeneratedUserStore()