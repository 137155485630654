
import {Options, Vue} from 'vue-class-component'
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import InfiniteList from "@/components/common/InfiniteList.vue";
import UserListItem from "@/components/UserListItem.vue";
import {settingsServiceApi} from "@/api/SettingsServiceApi";
import SWR from "@/api/SWR";
import Setting from "@/model/Setting";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import {useToast} from "vue-toastification";
import Rule from "@/model/Rule";
import MultiSelect from "@/components/common/MultiSelect.vue";
import Checkbox from "@/components/common/Checkbox.vue";

@Options({
  props: {
    state: Object
  },
  components: {
    InfiniteList, MultiSelect, AnimatedInput, LoadingButton, UserListItem, Checkbox
  }
})
export default class Settings extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  api = settingsServiceApi

  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null, unsavedSettings: boolean }

  rules: Rule[] = []
  rulesLoading: boolean = true

  prefixOptions: { value: string, label: string }[] = [
    { value: 'AND', label: this.i18n.$gettext('AND') },
    { value: 'OR', label: this.i18n.$gettext('OR') }
  ]
  fieldOptions: { value: string, label: string }[] = [
    { value: 'TEXT', label: this.i18n.$gettext('Text (body or attachment)') },
    { value: 'SENDER', label: this.i18n.$gettext('Sender') },
    { value: 'RECEIVER', label: this.i18n.$gettext('Recipient (To / Cc /Bcc)') },
    { value: 'TO', label: this.i18n.$gettext('Recipient (To)') },
    { value: 'CC', label: this.i18n.$gettext('Recipient (Cc)') },
    { value: 'SUBJECT', label: this.i18n.$gettext('Subject') },
    { value: 'ATTACHMENT', label: this.i18n.$gettext('Attachment (filename or metadata)') },
    //{ value: 'ATTACHMENT_TYPE', label: this.i18n.$gettext('Attachment type') },
    { value: 'HEADER', label: this.i18n.$gettext('Message Header') },
    { value: 'HAS_ATTACHMENT', label: this.i18n.$gettext('Has Attachment(s)') },
    { value: 'HAS_NO_ATTACHMENT', label: this.i18n.$gettext('Has no Attachment(s)') },
    { value: 'IS_SPAM', label: this.i18n.$gettext('Classified as Spam') },
    { value: 'IS_NOT_SPAM', label: this.i18n.$gettext('Not classified as Spam') }
  ]
  operatorOptions: { value: string, label: string }[] = [
    { value: 'CONTAINS_ALL', label: this.i18n.$gettext('contains all of') },
    { value: 'CONTAINS_ONE', label: this.i18n.$gettext('contains one of') },
    { value: 'CONTAINS_NONE', label: this.i18n.$gettext('contains none of') },
    { value: 'MATCHES_REGEX', label: this.i18n.$gettext('matches regex') }
  ]
  attachmentTypeOptions: { value: string, label: string }[] = [
    { value: 'PDF', label: this.i18n.$gettext('PDF Document') },
    { value: 'Office', label: this.i18n.$gettext('Office Document') },
    { value: 'Image', label: this.i18n.$gettext('Image / Video') }
  ]
  senderDisplayOptions: { value: string, label: string }[] = [
    { value: 'full', label: this.i18n.$gettext('Name & Email') },
    { value: 'email', label: this.i18n.$gettext('Email only') }
  ]
  rcptDisplayOptions: { value: string, label: string }[] = [
    { value: 'full', label: this.i18n.$gettext('Name & Email') },
    { value: 'email', label: this.i18n.$gettext('Email only') }
  ]
  addressDisplayOptions: { value: string, label: string }[] = [
    { value: 'to', label: this.i18n.$gettext('To only') },
    { value: 'condensed', label: this.i18n.$gettext('All Recipients') },
    { value: 'separate', label: this.i18n.$gettext('To, Cc, Bcc') }
  ]
  spamThresholdOptions: number[] = [...Array(10).keys()].map(n => n + 1)

  get validateRetentionPeriod(): string | null {
    if (this.retentionPeriod && this.retentionPeriod.value !== '' && isNaN(Number(this.retentionPeriod.value))) {
      return this.i18n.$gettext('Please enter a number in days or leave the field empty.')
    } else {
      return null
    }
  }

  get retentionPeriod(): Setting | undefined {
    return this.api.settings.find(setting => setting.key == 'RETENTION_PERIOD')
  }

  get senderDisplay(): Setting | undefined {
    return this.api.settings.find(setting => setting.key == 'SENDER_DISPLAY')
  }

  get rcptDisplay(): Setting | undefined {
    return this.api.settings.find(setting => setting.key == 'RECIPIENT_DISPLAY')
  }

  get resultAddressDisplay(): Setting | undefined {
    return this.api.settings.find(setting => setting.key == 'RESULT_ADDRESS_DISPLAY')
  }

  get detailsAddressDisplay(): Setting | undefined {
    return this.api.settings.find(setting => setting.key == 'DETAIL_ADDRESS_DISPLAY')
  }

  showImagesSetting: boolean | null = null

  get showImages(): boolean {
    return this.showImagesSetting === null ? this.api.settings.find(setting => setting.key == 'SHOW_IMAGES')?.value === 'true' : this.showImagesSetting
  }

  set showImages(show: boolean) {
    this.showImagesSetting = show
  }

  get spamThreshold(): Setting | undefined {
    return this.api.settings.find(setting => setting.key == 'SPAM_THRESHOLD')
  }

  async saveSettings(): Promise<void> {
    let success: boolean = true
    const showImagesSetting = this.api.settings.find(setting => setting.key == 'SHOW_IMAGES')
    if (showImagesSetting) {
      showImagesSetting.value = this.showImagesSetting ? 'true': 'false'
    }
    try {
      await this.api._changeSettings(this.api.settings.filter(setting => setting.key !== 'FILTER_RULES'))
    } catch (e) {
      success = false
      this.toast.error(this.i18n.$gettext('Failed to save settings.'))
    }
    try {
      let rules = this.rules.map(rule => Object.assign(new Rule(), rule))
      for (let rule of rules) {
        if (rule.field === 'HAS_ATTACHMENT') {
          rule.value = 'true'
        } else if (rule.field === 'HAS_NO_ATTACHMENT') {
          rule.field = 'HAS_ATTACHMENT'
          rule.value = 'false'
        } else if (rule.field === 'IS_SPAM') {
          rule.value = 'true'
        } else if (rule.field === 'IS_NOT_SPAM') {
          rule.field = 'IS_SPAM'
          rule.value = 'false'
        }
      }
      await this.api._setFilterRules(rules)
    } catch (e) {
      success = false
      this.toast.error(this.i18n.$gettext('Failed to save archiving rules.'))
    }
    if (success) {
      this.toast.success(this.i18n.$gettext('Settings saved.'))
    }
  }

  addRule() {
    let rule: Rule = new Rule()
    rule.prefix = 'AND'
    this.rules.push(rule)
  }

  get invalidRules(): boolean {
    return !!this.rules.find(rule => {
      if (!rule.prefix || !rule.field) {
        return true
      } else if (['HAS_ATTACHMENT', 'HAS_NO_ATTACHMENT', 'IS_SPAM', 'IS_NOT_SPAM'].indexOf(rule.field) < 0) {
        return !rule.operator || !rule.value
      }
    })
  }

  created() {
    this.api.settings
  }

  mounted() {
    this.api._getFilterRules().then((rules: Rule[]) => {
      this.rules = rules.map(rule => Object.assign(new Rule(), rule))
      for (let rule of this.rules) {
        if (rule.field === 'HAS_ATTACHMENT' && rule.value === 'false') {
          rule.field = 'HAS_NO_ATTACHMENT'
        } else if (rule.field === 'IS_SPAM' && rule.value === 'false') {
          rule.field = 'IS_NOT_SPAM'
        }
      }
    }).catch(e => {
      this.toast.error(this.i18n.$gettext('Failed to load archiving rules.'))
    }).finally(() => {
      this.rulesLoading = false
    })
  }
}
