
import {Options, Vue} from 'vue-class-component'
import User from "@/model/User"
import LoadingButton from "@/components/common/LoadingButton.vue"
import MultiSelect from "@/components/common/MultiSelect.vue";

@Options({
  components: {
    MultiSelect, LoadingButton
  },
  props: {
    user: Object,
    roleOptions: Object,
    disabled: Boolean
  }
})
export default class UserListItem extends Vue {

  user!: User
  roleOptions!: { value: string, label: string }[]
  disabled: boolean = false

}
