export default class User {
  userName!: string | null;
  email!: string | null;
  roles!: string[] | null;
  password!: string | null;
  created!: any | null;
  updated!: any | null;
  version!: number | null;

  constructor() {
    this.userName = null;
    this.email = null;
    this.roles = null;
    this.password = null;
    this.created = null;
    this.updated = null;
    this.version = null;
  }
}
