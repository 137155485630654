<template>
  <teleport to="body">
    <div class="modal fade" :class="{ 'show': modalShow, 'd-block': modalActive, 'modal-fullscreen': fullscreen, ...classes }" tabindex="-1" role="dialog" @click="toggle">
      <div class="modal-dialog" :class="size ? 'modal-' + size : ''" @click.stop="">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">
              <slot name="title"></slot>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="toggle">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modalActive" class="modal-backdrop fade show" :class="classes"></div>
  </teleport>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component";
import {Watch} from "vue-property-decorator";

@Options({
  props: {
    classes: Object,
    show: Boolean,
    size: String,
    fullscreen: Boolean
  },
  emits: [
      'show', 'hide'
  ]
})
export default class Modal extends Vue {

  show!: boolean
  size!: string
  classes!: any
  fullscreen!: boolean

  modalActive: boolean = false
  modalShow: boolean = false

  toggle() {
    const body = document.querySelector("body")
    this.modalActive = !this.modalActive
    if (body && this.modalActive) {
      body.classList.add("modal-open")
    } else if (body) {
      body.classList.remove("modal-open")
    }
    setTimeout(() => (this.modalShow = !this.modalShow), 10)
    if (this.modalActive) {
      this.$emit('show');
    } else {
      this.$emit('hide');
    }
  }

  @Watch('show')
  watchShowProp(newValue: boolean, oldValue: boolean) {
    if (newValue !== oldValue) {
      this.modalActive = oldValue
      this.modalShow = oldValue
      this.toggle()
    }
  }

  mounted() {
    if (this.show) {
      this.toggle()
    }
  }
}
</script>

<style lang="scss">
.modal-fullscreen {
  padding: 0 !important; // override inline padding-right added from js
}
.modal-fullscreen .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
</style>
