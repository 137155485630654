<template>
  <div class="row m-0 pt-2 pl-4 pr-4">
    <div class="col-12 col-md-4 d-flex align-items-center">
      <p class="lead text-dark font-weight-bold">{{ user.userName }}</p>
    </div>
    <div class="col-12 col-md-8">
      <MultiSelect class="mt-2 mb-3" mode="tags" v-model="user.roles" :options="roleOptions" :canDeselect="true" :disabled="disabled"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import User from "@/model/User"
import LoadingButton from "@/components/common/LoadingButton.vue"
import MultiSelect from "@/components/common/MultiSelect.vue";

@Options({
  components: {
    MultiSelect, LoadingButton
  },
  props: {
    user: Object,
    roleOptions: Object,
    disabled: Boolean
  }
})
export default class UserListItem extends Vue {

  user!: User
  roleOptions!: { value: string, label: string }[]
  disabled: boolean = false

}
</script>

<style lang="scss">

</style>
