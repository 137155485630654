import { reactive } from 'vue'
import Setting from '@/model/Setting';

class GeneratedSettingStore {

    state = reactive({
        settings: new Array<Setting>()
    })

    addOrReplaceSetting(newSetting: Setting) {
        let oldSetting = this.state.settings.find((value: Setting) => {
            return value.key === newSetting.key
        })

        if (oldSetting !== undefined) {
            this.state.settings = this.state.settings.map((value: Setting) => {
                if (value.key === newSetting.key) {
                    return newSetting
                } else {
                    return value
                }
            })
        } else {
            this.state.settings.push(newSetting)
        }
    }
    
    addOrReplaceSettings(newSettings: Setting[]) {
        let newState: Setting[] = this.state.settings.map((oldSetting: Setting) => {
            let newSettingIndex: number = -1
            for (let i = 0; i < newSettings.length; i++) {
                if (newSettings[i].key === oldSetting.key) {
                    newSettingIndex = i
                    break
                }
            }
            let setting: Setting
            if (newSettingIndex !== -1) {
                setting = newSettings[newSettingIndex]
                newSettings.splice(newSettingIndex, 1)
            } else {
                setting = oldSetting
            }
            return setting
        })
        for (let i = 0; i < newSettings.length; i++) {
            newState.push(newSettings[i])
        }
        this.state.settings = newState
    }

    replaceSettings(settings: Setting[]) {
        this.state.settings = settings
    }

    removeSetting(key: string) {
        let toDelete = this.state.settings.find((setting: Setting) => {
            return setting.key === key
        })

        if (toDelete !== undefined) {
            let index = this.state.settings.indexOf(toDelete)
            if (index > -1) {
                this.state.settings.splice(index, 1)
            }
        }
    }
}

export const settingStore = new GeneratedSettingStore()