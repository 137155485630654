import { reactive } from 'vue'
import Query from '@/model/Query';

class GeneratedQueryStore {

    state = reactive({
        querys: new Array<Query>()
    })

    addOrReplaceQuery(newQuery: Query) {
        let oldQuery = this.state.querys.find((value: Query) => {
            return value.id === newQuery.id
        })

        if (oldQuery !== undefined) {
            this.state.querys = this.state.querys.map((value: Query) => {
                if (value.id === newQuery.id) {
                    return newQuery
                } else {
                    return value
                }
            })
        } else {
            this.state.querys.push(newQuery)
        }
    }
    
    addOrReplaceQuerys(newQuerys: Query[]) {
        let newState: Query[] = this.state.querys.map((oldQuery: Query) => {
            let newQueryIndex: number = -1
            for (let i = 0; i < newQuerys.length; i++) {
                if (newQuerys[i].id === oldQuery.id) {
                    newQueryIndex = i
                    break
                }
            }
            let query: Query
            if (newQueryIndex !== -1) {
                query = newQuerys[newQueryIndex]
                newQuerys.splice(newQueryIndex, 1)
            } else {
                query = oldQuery
            }
            return query
        })
        for (let i = 0; i < newQuerys.length; i++) {
            newState.push(newQuerys[i])
        }
        this.state.querys = newState
    }

    replaceQuerys(querys: Query[]) {
        this.state.querys = querys
    }

    removeQuery(id: string) {
        let toDelete = this.state.querys.find((query: Query) => {
            return query.id === id
        })

        if (toDelete !== undefined) {
            let index = this.state.querys.indexOf(toDelete)
            if (index > -1) {
                this.state.querys.splice(index, 1)
            }
        }
    }
}

export const queryStore = new GeneratedQueryStore()