<template>
  <div id="detailbody">
    <div class="row">
      <div class="col">
        <p class="mb-1 text-dark"><span class="badge badge-light"><translate>From</translate>:</span>&emsp;{{ email.sender }}</p>
        <p v-if="separateRecipients" class="mb-1 text-dark"><span class="badge badge-light"><translate>To</translate>:</span>&emsp;{{ (email.to || []).join(', ') }}</p>
        <p v-if="separateRecipients && email.cc && email.cc.length" class="mb-1 text-dark"><span class="badge badge-light"><translate>Cc</translate>:</span>&emsp;{{ (email.cc || []).join(', ') }}</p>
        <p v-if="separateRecipients && email.bcc && email.bcc.length" class="mb-1 text-dark"><span class="badge badge-light"><translate>Bcc</translate>:</span>&emsp;{{ (email.bcc || []).join(', ') }}</p>
        <p v-if="!separateRecipients" class="mb-1 text-dark"><span class="badge badge-light"><translate>Recipients</translate>:</span>&emsp;{{ allRecipients.join(', ') }}</p>
        <p class="mb-1 text-dark"><span class="badge badge-light"><translate>Subject</translate>:</span>&emsp;{{ email.subject }}
          <!--<span v-if="email.threadId">&nbsp;<a href="javascript:" @click="showThread"><translate>Show Thread</translate></a></span>-->
        </p>
        <p class="mb-1 text-dark"><span class="badge badge-light"><translate>Date</translate>:</span>&emsp;{{ new Date(email.date).toLocaleString('de-DE') }}</p>
        <p class="mb-1"><span class="badge badge-light text-dark"><translate>Checksum</translate>:</span>&emsp;
          <i style="transform: translateY(2px)" v-if="email.digestVerified !== null" class="fa fa-lg" :class="email.digestVerified ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"></i>
          <span v-else><translate>Loading email and verifying checksum...</translate></span>
        </p>
      </div>
    </div>
    <hr class="mb-2">
    <div v-if="email.attachment && email.attachment.length > 0" class="mb-4">
      <span class="badge badge-light"><translate>Attachments</translate>:</span>&emsp;
      <button v-for="(a,i) in email.attachment" :key="a + i" class="btn btn-outline-primary p-1 pl-2 pr-2 m-2" @click="downloadAttachment(a)"><i class="fa fa-file mr-2"></i> {{ a }}</button>
      <hr class="mt-2">
    </div>
    <div class="row flex-grow-1">
      <div class="col d-flex flex-column" v-if="email && email.htmlBodies && email.htmlBodies.length > 0 && (mode === 'rich' || !email.textBodies || email.textBodies.length === 0)">
        <iframe
            id="htmlFrame"
            ref="emailIframe"
            style="height: 0; width: 100%"
            frameBorder="0"
            :src="'/emails/' + encodeURIComponent(email.digest) + '/html/?mode=' + mode + '&allowImages=' + allowImages"
            sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            @load="adjustIframeHeight"
        />
        <div v-if="!emailIframeLoaded && !email.htmlBodies?.length">
          <p key="loading"><translate>Loading email body...</translate></p>
        </div>
        <div v-else-if="!emailIframeLoaded" class="flex-grow-1 d-flex flex-column">
          <iframe
              v-for="(htmlBody, i) in email.htmlBodies"
              class="flex-grow-1"
              :ref="el => { htmlFrames[i] = el }"
              style="height: 0; width: 100%"
              frameBorder="0"
              :srcdoc="htmlBody"
              sandbox
              @load="adjustIframeHeight2"
          />
        </div>
      </div>
      <div class="col" v-else-if="email && email.textBodies && (email.textBodies.length > 0 || mode === 'plain')">
        <pre v-for="textBody in email.textBodies">{{ textBody }}</pre>
      </div>
      <div class="col" v-else-if="!email || email.digestVerified === null">
        <p key="loading"><translate>Loading email body...</translate></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/Email"
import Setting from "@/model/Setting";
import {settingsServiceApi} from "@/api/SettingsServiceApi";
import {ref} from "vue"
import {Watch} from "vue-property-decorator"

@Options({
  props: {
    email: Email,
    state: Object,
    mode: String
  }
})
export default class DetailBody extends Vue {

  settingsServiceApi = settingsServiceApi

  email!: Email
  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }
  mode: string = 'rich'
  emailIframeLoaded: boolean = false
  imagesAllowed: boolean | null = null

  //@ts-ignore
  emailIframe: HTMLIFrameElement | null = ref<HTMLIFrameElement | null>(null)
  htmlFrames: HTMLIFrameElement[] = []

  @Watch('email.digest')
  watchEmailDigest(oldDigest: string, newDigest: string) {
    if (oldDigest !== newDigest) {
      this.emailIframeLoaded = false
    }
  }

  get allowImages(): boolean {
    if (this.imagesAllowed === null) {
      return this.settingsServiceApi.settings.find(setting => setting.key == 'SHOW_IMAGES')?.value === 'true'
    } else {
      return this.imagesAllowed
    }
  }

  downloadAttachment(attachment: string) {
    if (this.email && this.email.digest) {
      window.open('/emails/' + encodeURIComponent(this.email.digest) + '/attachments/' + encodeURIComponent(attachment), '_blank')
    }
  }

  get separateRecipients(): boolean {
    let setting: Setting | undefined = settingsServiceApi.settings.find(setting => setting.key == 'DETAIL_ADDRESS_DISPLAY')
    if (setting) {
      return setting.value === 'separate'
    } else {
      return false
    }
  }

  get allRecipients() {
    return this.email ? (this.email.to || []).concat(this.email.cc || []).concat(this.email.bcc || []) : []
  }

  adjustIframeHeight(): void {
    try {
      const scrollHeight = this.emailIframe?.contentWindow?.document?.body?.scrollHeight
      if (scrollHeight && this.emailIframe) {
        let newHeight: number = scrollHeight + 50 // margin
        this.emailIframe.style.height = newHeight + 'px'
        this.emailIframeLoaded = true
      }
    } catch (ignore) {
    }
  }

  adjustIframeHeight2(): void {
    for (let iframe of this.htmlFrames) {
      try {
        const scrollHeight = iframe.contentWindow?.document?.body?.scrollHeight
        if (scrollHeight && this.emailIframe) {
          let newHeight: number = scrollHeight + 50 // margin
          this.emailIframe.style.height = newHeight + 'px'
        }
      } catch (ignore) {
      }
    }
  }

  toggleImages() {
    this.imagesAllowed = !this.allowImages
  }

  print() {
    if (!this.email?.digest) return
    const content: HTMLElement | undefined = document.querySelector('#detailbody') as HTMLElement
    if (content) {
      let height: number = content.clientHeight
      let width: number = content.clientWidth
      if (this.emailIframe?.contentWindow) {
        height = this.emailIframe.contentWindow.document.body.scrollHeight
        width = this.emailIframe.contentWindow.document.body.scrollWidth
      }
      const printWin: Window | null = window.open('', 'PRINT', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=' + (window.screen?.width || width) + 'px,height=' + (window.screen?.height || height) + 'px')
      if (printWin) {
        printWin.document.write('<html><head>' + document.head.innerHTML  + '</head><body><div id="printFrames" style="page-break-inside: avoid; position: relative; width: 100%">')
        printWin.document.write('<iframe id="printFrame" style="page-break-inside: avoid; border: none; box-shadow: none; width: 100%" src="' + window.location.origin + '/emails/' + encodeURIComponent(this.email.digest) + '/html/?allowImages=true&header=true" sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox" />')
        printWin.document.write('</div></body></html>')

        printWin.document.close() // necessary for IE >= 10
        printWin.focus() // necessary for IE >= 10*/

        this.printFrame(printWin, width, height)
      }
    }
  }

  printFrame(printWin: Window, width: number, height: number) {
    const printFrame: HTMLElement | null = printWin.document.getElementById('printFrame')
    if (printFrame) {
      printFrame.style.height = '100%'
      printFrame.style.width = '100%'
      printFrame.style.minHeight = height + 'px'
      printFrame.style.minWidth = width + 'px'
    }
    //@ts-ignore
    if (printFrame && printFrame.attachEvent) {
      const frame: HTMLIFrameElement = printFrame as HTMLIFrameElement
      //@ts-ignore
      printFrame.attachEvent('onload', function() {
        if (frame.contentWindow) {
          frame.contentWindow.document.body.style.overflow = 'hidden'
        }
        printWin.print()
        printWin.close()
      })
    } else if (printFrame) {
      const frame: HTMLIFrameElement = printFrame as HTMLIFrameElement
      printFrame.onload = function() {
        if (frame.contentWindow) {
          frame.contentWindow.document.body.style.overflow = 'hidden'
        }
        printWin.print()
        printWin.close()
      }
    } else {
      printWin.setTimeout(() => {
        this.printFrame(printWin, width, height)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
