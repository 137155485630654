
import {Options, Vue} from 'vue-class-component'
import SearchMask from './SearchMask.vue'
import ResultList from './ResultList.vue'
import DetailView from './DetailView.vue'
import Audit from "@/components/Audit.vue";
import Settings from "@/components/Settings.vue";
import {rpcClient} from "@/api/Vue3WebsocketClient";
import Modal from "@/components/common/Modal.vue";
import Users from "@/components/Users.vue";
import {reactive, ref} from "vue";

@Options({
  components: {
    ResultList, DetailView, SearchMask, Settings, Audit, Modal, Users
  }
})
export default class SearchInterface extends Vue {

  rpcClient = rpcClient

  //@ts-ignore
  detailCol: HTMLElement | null = ref<HTMLElement | null>(null)
  //@ts-ignore
  detailView: DetailView | null = ref<DetailView | null>(null)

  mode: string = 'search'
  state: { queryId: string | null, showSearch: boolean, emailDigest: string | null, unsavedSettings: boolean } = reactive({
    queryId: null,
    showSearch: true,
    emailDigest: null,
    unsavedSettings: false
  })

  get isAdmin() {
    return rpcClient.session.user && rpcClient.session.user.roles && (rpcClient.session.user.roles.indexOf('ADMIN') >= 0)
  }

  get isAudit() {
    return rpcClient.session.user && rpcClient.session.user.roles && (rpcClient.session.user.roles.indexOf('AUDIT') >= 0)
  }

  get width() {
    return window.innerWidth
  }

  mounted() {
    if (window.location.pathname.length > 20) {
      this.state.emailDigest = window.location.pathname.replace('/', '')
    }
    this.$nextTick(() => {
      this.detailCol?.addEventListener('transitionstart', (event) => {
        if (event.target === this.detailCol) {
          this.detailView?.detailButtons?.fadeIn()
        }
      })
    })
  }
}
