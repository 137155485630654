
import {Options, Vue} from 'vue-class-component'
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import AuditEntry from "@/model/AuditEntry";
import {auditServiceApi} from "@/api/AuditServiceApi";
import SWR from "@/api/SWR";
import InfiniteList from "@/components/common/InfiniteList.vue";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import {useToast} from "vue-toastification";
import MultiSelect from "@/components/common/MultiSelect.vue";

@Options({
  components: {
    InfiniteList, MultiSelect, AnimatedInput, LoadingButton
  }
})
export default class Audit extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  pageSize: number = 100
  filterBy: string[] = []
  filterOptions: { value: string, label: string }[] = [
    { value: 'QUERY', label: this.i18n.$gettext('Archive Query') },
    { value: 'DELETE', label: this.i18n.$gettext('Deletion') },
    { value: 'DOWNLOAD', label: this.i18n.$gettext('Download') },
    { value: 'CHANGE_ROLE', label: this.i18n.$gettext('Permissions Change') },
    { value: 'CHANGE_SETTING', label: this.i18n.$gettext('Settings Change') }
  ]

  get allItems(): () => AuditEntry[] {
    return auditServiceApi.getAuditEntrys
  }

  get itemPage(): ((pageIndex: number, pageSize: number) => SWR<AuditEntry[], number | boolean | void>) | null {
    return (pageIndex: number, pageSize: number) => {
      let filterBy: string[] | null = this.filterBy.length > 0 ? this.filterBy : null
      let swr = auditServiceApi.getAuditEntries(filterBy, pageIndex, pageSize, [ 'entryDate:desc' ], 1000)
      if (swr.call?.promise) {
        swr.call.promise.catch(e => {
          this.toast.error(this.i18n.$gettext('Failed to load audit entries.'))
        })
      }
      return swr;
    }
  }
}
