import { reactive } from 'vue'
import Email from '@/model/Email';

class GeneratedEmailStore {

    state = reactive({
        emails: new Array<Email>()
    })

    addOrReplaceEmail(newEmail: Email) {
        let oldEmail = this.state.emails.find((value: Email) => {
            return value.digest === newEmail.digest
        })

        if (oldEmail !== undefined) {
            this.state.emails = this.state.emails.map((value: Email) => {
                if (value.digest === newEmail.digest) {
                    return newEmail
                } else {
                    return value
                }
            })
        } else {
            this.state.emails.push(newEmail)
        }
    }
    
    addOrReplaceEmails(newEmails: Email[]) {
        let newState: Email[] = this.state.emails.map((oldEmail: Email) => {
            let newEmailIndex: number = -1
            for (let i = 0; i < newEmails.length; i++) {
                if (newEmails[i].digest === oldEmail.digest) {
                    newEmailIndex = i
                    break
                }
            }
            let email: Email
            if (newEmailIndex !== -1) {
                email = newEmails[newEmailIndex]
                newEmails.splice(newEmailIndex, 1)
            } else {
                email = oldEmail
            }
            return email
        })
        for (let i = 0; i < newEmails.length; i++) {
            newState.push(newEmails[i])
        }
        this.state.emails = newState
    }

    replaceEmails(emails: Email[]) {
        this.state.emails = emails
    }

    removeEmail(digest: string) {
        let toDelete = this.state.emails.find((email: Email) => {
            return email.digest === digest
        })

        if (toDelete !== undefined) {
            let index = this.state.emails.indexOf(toDelete)
            if (index > -1) {
                this.state.emails.splice(index, 1)
            }
        }
    }
}

export const emailStore = new GeneratedEmailStore()