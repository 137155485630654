import { rpcClient } from "@/api/Vue3WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "vue"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Setting from '@/model/Setting';
import Page from '@/model/Page';
import Rule from '@/model/Rule';
import { settingStore } from '@/store/generated/GeneratedSettingStore';

export default class GeneratedSettingsServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getSettings(): Promise<string[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getSettings', rpcParams).then((data: any) => {
            if (Array.isArray(data)) {
                const settings: Setting[] = data.map(setting => Object.assign(new Setting(), setting))
                settingStore.addOrReplaceSettings(settings)
                return settings.map(setting => setting.key || '')
            } else return Promise.reject()
        })
    }

    _changeSetting(key: string, value: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('changeSetting', rpcParams).then((data: any) => {
            const model = Object.assign(new Setting(), data)
            settingStore.addOrReplaceSetting(model)
            return model.key
        })
    }

    _changeSettings(arg0: Setting[] | null): Promise<string[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('changeSettings', rpcParams).then((data: any) => {
            if (Array.isArray(data)) {
                const settings: Setting[] = data.map(setting => Object.assign(new Setting(), setting))
                settingStore.addOrReplaceSettings(settings)
                return settings.map(setting => setting.key || '')
            } else return Promise.reject()
        })
    }

    _getFilterRules(): Promise<Rule[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getFilterRules', rpcParams).then((data: any) => {
            return Array.isArray(data) ? data.map(it => Object.assign(new Rule(), it)) : Promise.reject()
        })
    }

    _setFilterRules(rules: Rule[]): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setFilterRules', rpcParams) as Promise<void>
    }

    getSettings(refresh?: boolean | number): SWR<Setting[], string[]> {
        const result: SWR<Setting[], string[]> = reactive(new SWR<Setting[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, arguments.length - 1).filter(arg => arg !== undefined)
        const callId: string = '_getSettings' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        const refreshTime = typeof refresh === 'number' ? refresh : 1000
        if (cached && !cached.ended) {
            result.call = cached
        } else if (!cached || refresh === true || (refresh !== false && (cached.ended || 0) < (Date.now() - refreshTime))) {
            result.call = new Call<string[]>()
            this.cache.set(callId, result.call)
            result.call.loading = !cached
            result.call.refreshing = !!cached
            result.call.promise = this._getSettings().catch(e => {
                return Promise.reject(e)
            }).finally(() => {
                if (result.call) {
                    result.call.ended = Date.now()
                    result.call.loading = false
                    result.call.refreshing = false
                }
            })
        }
        let settings: Setting[] = [...settingStore.state.settings]
        
        
        
        result.data = settings
        return result
    }

    getSetting(key: string): Setting | undefined {
        return settingStore.state.settings.find((setting: Setting) => {
            return setting.key === key
        })
    }
}
