<template>
  <div class="h-100 mr-xl-2 ml-2">
    <div class="d-flex flex-column bg-white z-1 pl-4 pr-4 pt-2 pt-xl-3 pb-2 sort-bar">
      <div class="row">
        <div class="col-auto d-flex">
          <p class="text-right mb-2 mb-xl-1 align-self-center"><translate>Filter by action</translate>:</p>
        </div>
        <div class="col d-flex">
          <MultiSelect class="align-self-center" mode="tags" v-model="filterBy" :options="filterOptions" :canDeselect="true"/>
        </div>
      </div>
    </div>
    <div class="pt-2 result-list">
      <div class="d-flex flex-column bg-white z-1 h-100">
        <div class="row m-0 pt-4 pl-4 pr-4">
          <div class="col-4 d-none d-md-block">
            <h6 class="text-dark"><translate>User</translate></h6>
          </div>
          <div class="col-6 col-md-4">
            <h6 class="text-dark d-md-none mb-0"><translate>User</translate></h6>
            <h6 class="text-dark"><translate>Date</translate></h6>
          </div>
          <div class="col-6 col-md-4">
            <h6 class="text-dark"><translate>Action</translate></h6>
          </div>
        </div>
        <hr class="m-0">
        <InfiniteList :getAllItems="allItems" :getItemPage="itemPage" :pageSize="pageSize" v-slot="{ item }">
          <div class="row m-0 pt-3 pl-4 pr-4">
            <div class="col-4 d-none d-md-block">
              <p class="text-dark">{{ item.userName }}</p>
            </div>
            <div class="col-6 col-md-4">
              <p class="text-dark d-md-none mb-1">{{ item.userName }}</p>
              <p class="text-dark" v-if="item.entryDate">{{ new Date(item.entryDate).toLocaleString() }}</p>
            </div>
            <div class="col-6 col-md-4" v-if="item.operation === 'QUERY'">
              <p v-if="item.entryValue" class="text-dark"><translate :translate-params="{ subject: item.entryValue }">Opened the email thread "%{subject}".</translate></p>
              <p v-else class="text-dark"><translate>Searched the archive.</translate></p>
            </div>
            <div class="col-4" v-else-if="item.operation === 'DELETE'">
              <p class="text-dark"><translate :translate-params="{ hash: item.targetId.substring(0, 8), comment: item.entryValue }">Deleted the email with hash %{hash}. Comment: %{comment}</translate></p>
            </div>
            <div class="col-4" v-else-if="item.operation === 'DOWNLOAD'">
              <p class="text-dark"><translate :translate-params="{ hash: item.targetId.substring(0, 8) }">Downloaded the email with hash %{hash}.</translate></p>
            </div>
            <div class="col-4" v-else-if="item.operation === 'CHANGE_ROLE'">
              <p class="text-dark"><translate :translate-params="{ user: item.targetId, roles: item.entryValue }">Changed the roles of user %{user} to %{roles}.</translate></p>
            </div>
            <div class="col-4" v-else-if="item.operation === 'CHANGE_SETTING'">
              <p class="text-dark"><translate :translate-params="{ key: item.targetId, value: item.entryValue }">Changed the settings key %{key} to %{value}.</translate></p>
            </div>
          </div>
          <hr class="m-0">
        </InfiniteList>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import AuditEntry from "@/model/AuditEntry";
import {auditServiceApi} from "@/api/AuditServiceApi";
import SWR from "@/api/SWR";
import InfiniteList from "@/components/common/InfiniteList.vue";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import {useToast} from "vue-toastification";
import MultiSelect from "@/components/common/MultiSelect.vue";

@Options({
  components: {
    InfiniteList, MultiSelect, AnimatedInput, LoadingButton
  }
})
export default class Audit extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  pageSize: number = 100
  filterBy: string[] = []
  filterOptions: { value: string, label: string }[] = [
    { value: 'QUERY', label: this.i18n.$gettext('Archive Query') },
    { value: 'DELETE', label: this.i18n.$gettext('Deletion') },
    { value: 'DOWNLOAD', label: this.i18n.$gettext('Download') },
    { value: 'CHANGE_ROLE', label: this.i18n.$gettext('Permissions Change') },
    { value: 'CHANGE_SETTING', label: this.i18n.$gettext('Settings Change') }
  ]

  get allItems(): () => AuditEntry[] {
    return auditServiceApi.getAuditEntrys
  }

  get itemPage(): ((pageIndex: number, pageSize: number) => SWR<AuditEntry[], number | boolean | void>) | null {
    return (pageIndex: number, pageSize: number) => {
      let filterBy: string[] | null = this.filterBy.length > 0 ? this.filterBy : null
      let swr = auditServiceApi.getAuditEntries(filterBy, pageIndex, pageSize, [ 'entryDate:desc' ], 1000)
      if (swr.call?.promise) {
        swr.call.promise.catch(e => {
          this.toast.error(this.i18n.$gettext('Failed to load audit entries.'))
        })
      }
      return swr;
    }
  }
}
</script>

<style lang="scss">
.sort-bar {
  height: 60px
}
.result-list {
  height: calc(100% - 180px)
}
@media screen and (min-width: 1200px) {
  .sort-bar {
    height: 80px
  }
  .result-list {
    height: calc(100% - 80px)
  }
}
</style>
