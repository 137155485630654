
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/Email"
import {mailServiceApi} from "@/api/MailServiceApi";
import SWR from "@/api/SWR";
import LoadingButton from "@/components/common/LoadingButton.vue";
import Modal from "@/components/common/Modal.vue";
import {ref} from "vue";
import {useToast} from "vue-toastification";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import DetailBody from "@/components/DetailBody.vue";
import DetailButtons from "@/components/DetailButtons.vue";
import {windowWatcher} from "@/util/WindowWatcher"

@Options({
  components: {
    LoadingButton, Modal, DetailBody, DetailButtons
  },
  props: {
    state: Object
  }
})
export default class DetailView extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  windowWatcher = windowWatcher

  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }

  mode: string = 'rich'

  //@ts-ignore
  detailBody: DetailBody | null = ref<DetailBody | null>(null)
  //@ts-ignore
  detailButtons: DetailButtons | null = ref<DetailButtons | null>(null)

  close() {
    this.state.emailDigest = null
  }

  get email(): Email | null {
    if (this.state.emailDigest) {
      let swr: SWR<Email | null, string> = mailServiceApi.getEmail(this.state.emailDigest)
      let email: Email | null = swr.data
      if (swr.call?.promise) {
        swr.call.promise.catch(e => {
          if (e.data && e.data['exceptionTypeName'].endsWith('NotFoundException')) {
            this.toast.error(this.i18n.$gettext('The requested email does not exist.'))
          } else {
            this.toast.error(this.i18n.$gettext('Failed to load email details.'))
          }
        })
      }
      if (this.state.queryId && email) {
        email.query = this.state.queryId
      }
      return email
    } else {
      return null
    }
  }

  toggleImages() {
    if (this.detailBody) {
      this.detailBody.toggleImages()
    }
  }

  print() {
    if (this.detailBody) {
      this.detailBody.print()
    }
  }
}
