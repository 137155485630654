
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/Email";

@Options({
  props: {
    email: Email,
    selected: Boolean,
    separateRecipients: Boolean
  }
})
export default class ResultListItem extends Vue {

  email!: Email
  selected: boolean = false
  separateRecipients: boolean = false

  get date(): Date | null {
    return (this.email && this.email.date) ? new Date(this.email.date) : null
  }

  get backgroundClass() {
    if (this.selected) {
      return 'bg-light'
    } else {
      return 'bg-hover-light'
    }
  }

  get allRecipients() {
    return this.email ? (this.email.to || []).concat(this.email.cc || []).concat(this.email.bcc || []) : []
  }
}
