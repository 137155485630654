<template>
  <div :class="backgroundClass" class="p-4 d-flex" style="cursor: pointer">
    <div class="flex-grow-1">
      <p class="mb-1 text-dark" style="text-overflow: fade"><span class="badge badge-light"><translate>From</translate>:</span>&emsp;{{ email.sender }}</p>
      <p v-if="separateRecipients" class="mb-1 text-dark"><span class="badge badge-light"><translate>To</translate>:</span>&emsp;{{ (email.to || []).join(', ') }}</p>
      <p v-if="separateRecipients && email.cc && email.cc.length" class="mb-1 text-dark"><span class="badge badge-light"><translate>Cc</translate>:</span>&emsp;{{ (email.cc || []).join(', ') }}</p>
      <p v-if="separateRecipients && email.bcc && email.bcc.length" class="mb-1 text-dark"><span class="badge badge-light"><translate>Bcc</translate>:</span>&emsp;{{ (email.bcc || []).join(', ') }}</p>
      <p v-if="!separateRecipients" class="mb-1 text-dark"><span class="badge badge-light"><translate>Recipients</translate>:</span>&emsp;{{ allRecipients.join(', ') }}</p>
      <p class="mb-1 text-dark" v-if="email.subject"><span class="badge badge-light" v-if="email.subject"><translate>Subject</translate>:</span>&emsp;{{ email.subject }}</p>
    </div>
    <div v-if="email.date" class="pl-2">
      <p class="small mb-2">{{ date.toLocaleDateString('de-DE') }}</p>
      <p class="small">{{ date.toLocaleTimeString('de-DE') }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/Email";

@Options({
  props: {
    email: Email,
    selected: Boolean,
    separateRecipients: Boolean
  }
})
export default class ResultListItem extends Vue {

  email!: Email
  selected: boolean = false
  separateRecipients: boolean = false

  get date(): Date | null {
    return (this.email && this.email.date) ? new Date(this.email.date) : null
  }

  get backgroundClass() {
    if (this.selected) {
      return 'bg-light'
    } else {
      return 'bg-hover-light'
    }
  }

  get allRecipients() {
    return this.email ? (this.email.to || []).concat(this.email.cc || []).concat(this.email.bcc || []) : []
  }
}
</script>

<style lang="scss">

</style>
