import { rpcClient } from "@/api/Vue3WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "vue"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Query from '@/model/Query';
import Page from '@/model/Page';
import { queryStore } from '@/store/generated/GeneratedQueryStore';

export default class GeneratedQueryServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _startQuery(query: Query): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('startQuery', rpcParams).then((data: any) => {
            const model = Object.assign(new Query(), data)
            queryStore.addOrReplaceQuery(model)
            return model.id
        })
    }

    _getSavedQueries(): Promise<string[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getSavedQueries', rpcParams).then((data: any) => {
            if (Array.isArray(data)) {
                const querys: Query[] = data.map(query => Object.assign(new Query(), query))
                queryStore.addOrReplaceQuerys(querys)
                return querys.map(query => query.id || '')
            } else return Promise.reject()
        })
    }

    _saveQuery(query: Query): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('saveQuery', rpcParams).then((data: any) => {
            const model = Object.assign(new Query(), data)
            queryStore.addOrReplaceQuery(model)
            return model.id
        })
    }

    getSavedQueries(sortBy?: string[] | string, refresh?: boolean | number): SWR<Query[], string[]> {
        const result: SWR<Query[], string[]> = reactive(new SWR<Query[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, arguments.length - 1).filter(arg => arg !== undefined)
        const callId: string = '_getSavedQueries' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        const refreshTime = typeof refresh === 'number' ? refresh : 1000
        if (cached && !cached.ended) {
            result.call = cached
        } else if (!cached || refresh === true || (refresh !== false && (cached.ended || 0) < (Date.now() - refreshTime))) {
            result.call = new Call<string[]>()
            this.cache.set(callId, result.call)
            result.call.loading = !cached
            result.call.refreshing = !!cached
            result.call.promise = this._getSavedQueries().catch(e => {
                return Promise.reject(e)
            }).finally(() => {
                if (result.call) {
                    result.call.ended = Date.now()
                    result.call.loading = false
                    result.call.refreshing = false
                }
            })
        }
        let querys: Query[] = [...queryStore.state.querys]
        
        SortAndFilterUtil.sort(querys, sortBy)
        
        result.data = querys
        return result
    }

    getQuerysFilterByOwnedBy(ownedBy: string, sortBy?: string[] | string, pageStart?: number, pageSize?: number, refresh?: boolean | number): SWR<Query[], string[]> {
        const result: SWR<Query[], string[]> = reactive(new SWR<Query[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, arguments.length - 1).filter(arg => arg !== undefined)
        const callId: string = '_getSavedQueries' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        const refreshTime = typeof refresh === 'number' ? refresh : 1000
        if (cached && !cached.ended) {
            result.call = cached
        } else if (!cached || refresh === true || (refresh !== false && (cached.ended || 0) < (Date.now() - refreshTime))) {
            result.call = new Call<string[]>()
            this.cache.set(callId, result.call)
            result.call.loading = !cached
            result.call.refreshing = !!cached
            result.call.promise = this._getSavedQueries().catch(e => {
                return Promise.reject(e)
            }).finally(() => {
                if (result.call) {
                    result.call.ended = Date.now()
                    result.call.loading = false
                    result.call.refreshing = false
                }
            })
        }
        let querys: Query[] = [...queryStore.state.querys]
        querys = SortAndFilterUtil.filter(querys, { ownedBy: ownedBy })
        SortAndFilterUtil.sort(querys, sortBy)
        if (pageStart !== undefined && pageStart !== null && pageSize !== undefined && pageSize !== null) {
            querys = querys.slice(pageStart * pageSize, (pageStart + 1) * pageSize)
        }
        result.data = querys
        return result
    }

    getQuery(id: string): Query | undefined {
        return queryStore.state.querys.find((query: Query) => {
            return query.id === id
        })
    }
}
