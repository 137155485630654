
import {Options, Vue} from 'vue-class-component'
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Query from "@/model/Query"
import {mailServiceApi} from "@/api/MailServiceApi"
import {queryServiceApi} from "@/api/QueryServiceApi";
import {useToast} from "vue-toastification";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import Checkbox from "@/components/common/Checkbox.vue";
import Datepicker from "@/components/common/DatePicker.vue";
import Rule from "@/model/Rule";
import Modal from "@/components/common/Modal.vue";
import {ref} from "vue";
import MultiSelect from "@/components/common/MultiSelect.vue";
import SWR from "@/api/SWR";

@Options({
  components: {
    AnimatedInput, LoadingButton, Checkbox, Datepicker, MultiSelect, Modal
  },
  props: {
    state: Object
  }
})
export default class SearchMask extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }

  //@ts-ignore
  saveQueryModal: Modal = ref<Modal | null>(null)

  //@ts-ignore
  loadQueryModal: Modal = ref<Modal | null>(null)

  savedQueryName: string = ''

  all: string = ''
  from: string = ''
  to: string = ''
  subject: string = ''
  content: string = ''
  attachment: string = ''
  start: Date | null = null
  end: Date | null = null
  withAttachment: boolean = false
  includeSpam: boolean = false
  advanced: boolean = false

  rules: Rule[] = []

  prefixOptions: { value: string, label: string }[] = [
    { value: 'AND', label: this.i18n.$gettext('AND') },
    { value: 'OR', label: this.i18n.$gettext('OR') }
  ]
  fieldOptions: { value: string, label: string }[] = [
    { value: 'TEXT', label: this.i18n.$gettext('Text (body or attachment)') },
    { value: 'SENDER', label: this.i18n.$gettext('Sender') },
    { value: 'RECEIVER', label: this.i18n.$gettext('Recipient (To / Cc / Bcc)') },
    { value: 'TO', label: this.i18n.$gettext('Recipient (To)') },
    { value: 'CC', label: this.i18n.$gettext('Recipient (Cc)') },
    { value: 'SUBJECT', label: this.i18n.$gettext('Subject') },
    { value: 'ATTACHMENT', label: this.i18n.$gettext('Attachment (filename or metadata)') },
    { value: 'HEADER', label: this.i18n.$gettext('Message Header') }
  ]
  operatorOptions: { value: string, label: string }[] = [
    { value: 'CONTAINS_ONE', label: this.i18n.$gettext('contains one') },
    { value: 'CONTAINS_ALL', label: this.i18n.$gettext('contains all') },
    { value: 'CONTAINS_NONE', label: this.i18n.$gettext('contains none') }
  ]

  get query(): Query {
    let query: Query = new Query()
    if (this.advanced) {
      query.content = this.content
      query.from = this.from
      query.to = this.to
      query.subject = this.subject
      query.attachment = this.attachment
      query.start = this.start
      query.end = this.end
      query.withAttachment = this.withAttachment
      query.includeSpam = this.includeSpam
      query.rules = this.rules
    } else {
      query.all = this.all
    }
    return query
  }

  queryEmails() {
    queryServiceApi._startQuery(this.query).then((queryId: string) => {
      mailServiceApi.resetState()
      Object.assign(this.state, { queryId: queryId, showSearch: false })
    }).catch(e => {
      this.toast.error(this.i18n.$gettext('Failed to start query.'))
    })
  }

  saveQuery() {
    let query: Query = this.query
    query.savedName = this.savedQueryName
    queryServiceApi._saveQuery(query).then(() => {
      this.toast.success(this.i18n.$gettext('The query has been saved.'))
      this.savedQueryName = ''
      this.saveQueryModal.toggle()
    }).catch(e => {
      this.toast.error(this.i18n.$gettext('Failed to save query.'))
    })
  }

  get savedQueries(): SWR<Query[], string[]> {
    return queryServiceApi.getSavedQueries([ 'created:desc' ])
  }

  loadQuery(id: string) {
    let query: Query | undefined = queryServiceApi.getQuery(id)
    if (query) {
      this.content = query.content || ''
      this.from = query.from || ''
      this.to = query.to || ''
      this.subject = query.subject || ''
      this.attachment = query.attachment || ''
      this.start = query.start ? new Date(query.start) : null
      this.end = query.end ? new Date(query.end) : null
      this.withAttachment = query.withAttachment || false
      this.includeSpam = query.includeSpam || false
      this.rules = query.rules || []
      this.all = query.all || ''
      this.advanced = Boolean(query.content || query.from || query.to || query.subject || query.attachment ||
          query.start || query.end || query.withAttachment || query.includeSpam || (query.rules && query.rules.length > 0))
    }
    this.loadQueryModal.toggle()
  }

  addRule() {
    let rule: Rule = new Rule()
    rule.prefix = 'AND'
    this.rules.push(rule)
  }

  get invalidRules(): boolean {
    return !!this.rules.find(rule => !(rule.prefix && rule.field && rule.operator))
  }
}
