import Rule from '@/model/Rule';

export default class Query {
  id!: string | null;
  createdBy!: string | null;
  ownedBy!: string | null;
  sharedWith!: string[] | null;
  savedName!: string | null;
  all!: string | null;
  from!: string | null;
  to!: string | null;
  subject!: string | null;
  content!: string | null;
  attachment!: string | null;
  start!: any | null;
  end!: any | null;
  header!: Map<string, string> | null;
  withAttachment!: boolean | null;
  includeSpam!: boolean | null;
  threadId!: string | null;
  rules!: Rule[] | null;
  created!: any | null;
  updated!: any | null;
  version!: number | null;

  constructor() {
    this.id = null;
    this.createdBy = null;
    this.ownedBy = null;
    this.sharedWith = null;
    this.savedName = null;
    this.all = null;
    this.from = null;
    this.to = null;
    this.subject = null;
    this.content = null;
    this.attachment = null;
    this.start = null;
    this.end = null;
    this.header = null;
    this.withAttachment = null;
    this.includeSpam = null;
    this.threadId = null;
    this.rules = null;
    this.created = null;
    this.updated = null;
    this.version = null;
  }
}
