<template>
  <button type="button" :class="classes" :type="type" :variant="variant" @click="callRegisteredAction">
    <slot></slot>
    <div class="button-background button-background-left"></div>
    <div class="button-background button-background-right"></div>
  </button>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";

  @Options({
    props: {
      variant: String,
      addClasses: String,
      action: Function,
      inline: Boolean,
      arguments: Object
    }
  })
  export default class LoadingButton extends Vue {
    variant!: string;
    addClasses!: string;
    action!: (any: any) => Promise<any>;
    arguments!: any;

    loading: boolean = false;
    hideText: boolean = false;
    type: string = "button";

    get classes (): string {
      return 'btn ' + (this.addClasses ? this.addClasses : '') + (this.loading ? ' loading' : '') + (this.hideText ? ' hide-text' : '');
    }

    callRegisteredAction () {
      if (!this.loading) { //Prevent additional clicks!
        this.loading = true;
        this.hideText = true;
        let func = this.action;
        let promise: Promise<any> = func(this.arguments);
        if (promise) {
          promise.then((successObject: any) => {
            this.loading = false;
            setTimeout(() => {
              this.hideText = false;
            }, 400);

            if (!successObject) {
              return;
            }

            if (successObject.hasOwnProperty("redirect") && typeof successObject.redirect === "string") {
              //TODO this.$router.push(successObject.redirect);
            }

            if (successObject.hasOwnProperty("callback") && typeof successObject.callback === "function") {
              successObject.callback();
            }
          }, (errorObject: any) => {
            this.loading = false;
            setTimeout(() => {
              this.hideText = false;
            }, 400);

            //Test if we caught a platform error:
            if (errorObject instanceof Error) {
              throw errorObject;
            }

            if (!errorObject) {
              return;
            }

            if (errorObject.hasOwnProperty("redirect") && typeof errorObject.redirect === "string") {
              //TODO $router.push(errorObject.redirect);
            }

            if (errorObject.hasOwnProperty("callback") && typeof errorObject.callback === "function") {
              errorObject.callback();
            }
          });
        } else {
          this.loading = false;
          setTimeout(() => {
            this.hideText = false;
          }, 400);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .btn.hide-text {
    color: transparent !important;
  }

  .btn {
    transition: transform .5s ease-in-out, color .1s ease-in-out;
    min-width: 100px;
  }

  .btn.loading {
    -webkit-transform: scale(1.0, 0.4);
    transform: scale(1.0, 0.4);
    color: transparent !important;
    cursor: default !important;
  }

  .btn.loading:before {
    display: none;
  }
</style>
