<template>
  <div class="h-100 mr-xl-2 ml-xl-2 d-none d-xl-block" v-if="email">
    <DetailButtons
        ref="detailButtons"
        class="d-flex flex-column bg-white z-1 p-3 button-bar"
        :email="email"
        :state="state"
        v-model="mode"
        @delete="close"
        @email:print="print"
        @email:toggleImages="toggleImages"
    />
    <div class="pt-2 email-view">
      <div class="d-flex flex-column bg-white z-1 p-4 h-100" style="overflow: auto" v-if="mode === 'source'">
        <pre>{{ email.rawEmail }}</pre>
      </div>
      <DetailBody
          v-else-if="windowWatcher.width >= 1200"
          ref="detailBody"
          class="d-flex flex-column bg-white z-1 p-4 h-100"
          style="overflow: auto"
          :email="email"
          :state="state"
          :mode="mode"
      />
    </div>

    <Modal v-if="windowWatcher.width < 1200" :show="!!state.emailDigest" fullscreen @hide="close">
      <template v-slot:title>
        <DetailButtons
            ref="detailButtons"
            class="d-flex flex-column bg-white p-3 button-bar"
            :email="email"
            :state="state"
            v-model="mode"
            @delete="close"
            @email:print="print"
            @email:toggleImages="toggleImages"
        />
      </template>
      <template v-slot:body>
        <div class="pt-2 email-view">
          <div class="d-flex flex-column bg-white h-100" style="overflow: auto" v-if="mode === 'source'">
            <pre>{{ email.rawEmail }}</pre>
          </div>
          <DetailBody
              ref="detailBody"
              class="d-flex flex-column bg-white h-100"
              :class="{ 'd-none': mode === 'source' }"
              style="overflow: auto"
              :email="email"
              :state="state"
              :mode="mode"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/Email"
import {mailServiceApi} from "@/api/MailServiceApi";
import SWR from "@/api/SWR";
import LoadingButton from "@/components/common/LoadingButton.vue";
import Modal from "@/components/common/Modal.vue";
import {ref} from "vue";
import {useToast} from "vue-toastification";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import DetailBody from "@/components/DetailBody.vue";
import DetailButtons from "@/components/DetailButtons.vue";
import {windowWatcher} from "@/util/WindowWatcher"

@Options({
  components: {
    LoadingButton, Modal, DetailBody, DetailButtons
  },
  props: {
    state: Object
  }
})
export default class DetailView extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  windowWatcher = windowWatcher

  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }

  mode: string = 'rich'

  //@ts-ignore
  detailBody: DetailBody | null = ref<DetailBody | null>(null)
  //@ts-ignore
  detailButtons: DetailButtons | null = ref<DetailButtons | null>(null)

  close() {
    this.state.emailDigest = null
  }

  get email(): Email | null {
    if (this.state.emailDigest) {
      let swr: SWR<Email | null, string> = mailServiceApi.getEmail(this.state.emailDigest)
      let email: Email | null = swr.data
      if (swr.call?.promise) {
        swr.call.promise.catch(e => {
          if (e.data && e.data['exceptionTypeName'].endsWith('NotFoundException')) {
            this.toast.error(this.i18n.$gettext('The requested email does not exist.'))
          } else {
            this.toast.error(this.i18n.$gettext('Failed to load email details.'))
          }
        })
      }
      if (this.state.queryId && email) {
        email.query = this.state.queryId
      }
      return email
    } else {
      return null
    }
  }

  toggleImages() {
    if (this.detailBody) {
      this.detailBody.toggleImages()
    }
  }

  print() {
    if (this.detailBody) {
      this.detailBody.print()
    }
  }
}
</script>

<style lang="scss">
.button-bar {
  height: 80px
}
.email-view {
  height: 100%
}
@media screen and (min-width: 1200px) {
  .button-bar {
    height: 80px
  }
  .email-view {
    height: calc(100% - 80px)
  }
}
@media screen and (min-width: 1900px) {
  .button-bar {
    height: 86px
  }
  .email-view {
    height: calc(100% - 86px)
  }
}
</style>
