<template>
  <label class="animated-input">
    <span :class="labelClass">{{label}}</span>
    <Multiselect :disabled="disabled" :mode="mode" :options="options" :canDeselect="canDeselect" :class="animationClass" :maxHeight="maxHeight || 200" v-model="internalValue" :placeholder="placeholder" @select="handleInput" @deselect="handleInput" @open="handleFocus" @close="handleBlur" ref="myinput"/>
  </label>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {Watch} from "vue-property-decorator";
import {ref} from "vue";
import Multiselect from "@vueform/multiselect";

@Options({
  components: {
    Multiselect
  },
  props: {
    mode: String,
    options: Object,
    canDeselect: Boolean,
    maxHeight: Number,
    label: String,
    placeholder: String,
    modelValue: [ Array, String ],
    inline: Boolean,
    autocomplete: String,
    error: String,
    type: String,
    disabled: Boolean,
    name: String,
    autofocus: Boolean
  }
})
export default class MultiSelect extends Vue {

  mode!: string
  options!: any
  canDeselect!: boolean
  maxHeight!: number
  label!: string;
  placeholder!: string;
  modelValue!: string | any[];
  inline!: boolean;
  autocomplete!: string;
  error!: string;
  type!: string;
  disabled!: boolean;
  name!: string;
  autofocus!: boolean;

  focused: boolean = false;
  internalValue: string | any[] = this.modelValue || '';

  //@ts-ignore
  myinput: Multiselect = ref<Multiselect | null>(null);

  get animationClass (): string {
    if (this.focused) {
      return 'ai-filled' + this.errorClass;
    } else if (Array.isArray(this.internalValue) && this.internalValue.length > 0) {
      return 'ai-filled' + this.errorClass;
    } else if (!Array.isArray(this.internalValue) && this.internalValue && this.internalValue !== '') {
      return 'ai-filled' + this.errorClass;
    } else {
      return this.errorClass
    }
  }

  get labelClass (): string {
    return (this.inline ? "input-label-inline" : "input-label");
  }

  get errorClass(): string {
    if (this.error) {
      if (this.focused) {
        return ' border-danger';
      } else {
        return ' border-danger danger';
      }
    } else {
      return '';
    }
  }

  handleInput () {
    this.$emit('update:modelValue', this.internalValue);
  }

  handleFocus () {
    this.focused = true;
    this.$emit('focus');
  }

  handleBlur () {
    this.focused = false;
    this.$emit('blur');
  }

  @Watch('modelValue')
  onValueChanged (val: string, oldVal: string) {
    this.internalValue = val;
  }

  //Do things when we're in the dom:
  mounted () {
    if (this.autofocus) {
      this.myinput.open()
    }
  }
};
</script>

<style lang="scss" scoped>
 .input-group-input > .animated-input {
    margin-bottom: 0;
  }
 .multiselect.ai-filled:before {
   transform: scaleX(1);
 }
 .multiselect:before {
   content: "";
   position: absolute;
   left: 0;
   right: 0;
   bottom: 0;
   height: calc(3rem + 4px);
   background: #1C1B3C;
   transform-origin: 100% 50%;
   transform: scaleX(0);
   transition: all 0.2s ease-in-out;
 }
</style>
