
import { Options, Vue } from 'vue-class-component'
import { mailServiceApi } from "@/api/MailServiceApi"
import Email from "@/model/Email";
import SWR from "@/api/SWR";
import ResultListItem from "@/components/ResultListItem.vue"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import {useToast} from "vue-toastification";
import MultiSelect from "@/components/common/MultiSelect.vue";
import Setting from "@/model/Setting";
import {settingsServiceApi} from "@/api/SettingsServiceApi";

@Options({
  components: {
    InfiniteList, MultiSelect, ResultListItem
  },
  props: {
    state: Object
  }
})
export default class ResultList extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }

  pageSize: number = 50
  sortBy: string = 'date:desc'
  sortOptions: { value: string, label: string }[] = [
    { value: 'score:desc', label: this.i18n.$gettext('Accuracy') },
    { value: 'date:asc', label: this.i18n.$gettext('Date ascending') },
    { value: 'date:desc', label: this.i18n.$gettext('Date descending') },
    { value: 'sender:asc', label: this.i18n.$gettext('Sender ascending') },
    { value: 'sender:desc', label: this.i18n.$gettext('Sender descending') }
  ]

  get total(): number | null {
    return this.state.queryId ? mailServiceApi.state.total : null
  }

  get allItems(): Email[] {
    const sortBy = this.sortBy //Access sortBy in order to make it reactive!
    return mailServiceApi.getEmails(sortBy)
  }

  get itemPage(): ((pageIndex: number, pageSize: number) => SWR<Email[], number | boolean | void>) | null {
    if (this.state.queryId) {
      const queryId = this.state.queryId
      const sortBy = this.sortBy //Access sortBy in order to make it reactive!
      return (pageIndex: number, pageSize: number) => {
        let swr: SWR<Email[], number | boolean | void> = mailServiceApi.queryEmails(queryId, sortBy, pageIndex, pageSize, false)
        if (swr.call?.promise) swr.call?.promise.then((max: number | boolean | void) => {
          if (typeof max === 'number') mailServiceApi.state.total = max
          return max
        }).catch(e => {
          this.toast.error(this.i18n.$gettext('Failed to load results.'))
        })
        return swr
      }
    } else {
      return null
    }
  }

  get separateRecipients(): boolean {
    let setting: Setting | undefined = settingsServiceApi.settings.find(setting => setting.key == 'RESULT_ADDRESS_DISPLAY')
    if (setting) {
      return setting.value === 'separate'
    } else {
      return false
    }
  }

  selectEmail(digest: string) {
    this.state.emailDigest = digest
  }
}
