<template>
  <label class="animated-input" :class="animationClass">
    <span :class="labelClass">{{label}}</span>
    <datepicker v-model="internalValue" ref="myinput" :placeholder="placeholder" @focus="handleFocus" @blur="handleBlur"/>
  </label>
</template>

<script lang="ts">

import Datepicker from 'vue3-datepicker'
import {Options, Vue} from "vue-class-component";
import {Watch} from "vue-property-decorator";
import {ref} from "vue";

@Options({
  props: {
    label: String,
    placeholder: String,
    modelValue: Date,
    inline: Boolean,
    autocomplete: String,
    error: String,
    type: String,
    disabled: Boolean,
    name: String
  },
  components: {
    Datepicker
  }
})
export default class DatePicker extends Vue {

  label!: string;
  placeholder!: string;
  modelValue!: Date;
  inline!: boolean;
  autocomplete!: string;
  error!: string;
  type!: string;
  disabled!: boolean;
  name!: string;

  focused: boolean = false;
  internalValue: Date | null = this.modelValue || null;

  //@ts-ignore
  myinput: HTMLInputElement = ref<HTMLInputElement | null>(null);

  get animationClass (): string {
    if (this.focused || (this.internalValue)) {
      return 'ai-filled' + this.errorClass;
    } else {
      return '' + this.errorClass;
    }
  }

  get labelClass (): string {
    return (this.inline ? "input-label-inline" : "input-label");
  }

  get errorClass(): string {
    if (this.error) {
      if (this.focused) {
        return ' border-danger';
      } else {
        return ' border-danger danger';
      }
    } else {
      return '';
    }
  }

  @Watch('internalValue')
  handleInput (val: Date, oldVal: Date) {
    this.$emit('update:modelValue', this.internalValue);
  }

  handleFocus () {
    this.focused = true;
    this.$emit('focus');
  }

  handleBlur () {
    this.focused = false;
    this.$emit('blur');
  }

  @Watch('modelValue')
  onValueChanged (val: Date, oldVal: Date) {
    this.internalValue = val;
  }
}
</script>

<style lang="scss">
.v3dp__datepicker {
  --vdp-hover-bg-color:  #47BBC7;
  --vdp-selected-bg-color:  #47BBC7;
  --vdp-text-color: #1c1b3c;
  --vdp-box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.3);
}
.v3dp__datepicker input {
  color: white;
  position: relative;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  border: none;
  border-bottom: #1C1B3C solid 2px;
  font-size: 0.8rem;
  cursor: text;
  z-index: 1;
  background-color: rgba(28, 27, 60, 0.1);
  padding: 0.5rem 1rem;
  height: calc(1.625em + 1.7rem + 4px);
}
.v3dp__datepicker :focus-visible {
  outline: transparent;
}
</style>
