<template>
  <div class="custom-control custom-checkbox" @click="toggle">
    <input class="custom-control-input" type="checkbox" :disabled="disabled" :name="name" :checked="modelValue">
    <label class="custom-control-label">
      <slot></slot>
    </label>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component";

@Options({
  props: {
    modelValue: Boolean,
    checked: Boolean,
    type: String,
    disabled: Boolean,
    name: String
  }
})
export default class Checkbox extends Vue {

  modelValue!: boolean
  checked!: boolean
  type!: string
  disabled!: boolean
  name!: string

  toggle() {
    this.$emit('update:modelValue', !this.modelValue);
  }

}
</script>

<style lang="scss">

</style>
