
import {ref} from 'vue'
import {Options, Vue} from 'vue-class-component'
import {Watch} from "vue-property-decorator"
import Email from "@/model/Email";
import SWR from "@/api/SWR";

@Options({
  props: {
    getAllItems: [ Array, Function ],
    getItemPage: Function,
    loadItems: Function,
    pageSize: Number,
    containerHeight: Number,
    scrollDirection: Number
  }
})
export default class InfiniteList extends Vue {

  //@ts-ignore
  root: HTMLElement = ref<HTMLElement | null>(null)
  //@ts-ignore
  viewport: HTMLElement = ref<HTMLElement | null>(null)

  rows: Map<any, HTMLElement> = new Map<any, HTMLElement>()
  rowHeights: Map<any, number> = new Map<any, number>()

  // The array of all available items or a function returning all available items
  getAllItems!: (() => any[]) | any[]
  // A function that returns a page of items
  getItemPage!: ((pageIndex: number, pageSize: number) => SWR<any[], number | boolean | void>) | null
  // A function that loads more items, adds them to the state of getItems and returns either the known total number of items or nothing
  loadItems!: ((pageIdx: number, max: number) => Promise<number | boolean | void>) | null
  pageSize: number = 100
  containerHeight: string | null = "100%"
  // The scroll direction, 1 for down, -1 for up
  scrollDirection: number = 1

  // Total number of items if known (returned by loadItems)
  totalItems: number | null = null
  // When scroll direction is inverse, the total height should only be estimated once
  initialTotalItems: number = 0
  // Are items currently loading as part of the infinite scroll?
  loading: boolean = false
  // Index of the page which contains the last visible item
  currentPage: number = -1
  // Total height per page
  // On page 0 , lets say all PAGE_SIZE rows add up to 2000
  // On page 1, lets say all PAGE_SIZE rows add up to 2500, then
  // pagePositions: [2000, 4500]
  // page 1 = page 0 height of PAGE_SIZE items + page 1 height of PAGE_SIZE items
  pagePositions: number[] = []
  // How much to shift the spacer vertically so that the scrollbar is not disturbed when hiding items
  translateY: number = 0
  // Total height of all the rows of all the pages
  listHeight: number | null = null

  get items(): any[] { // Wrap the function in a getter so that the result gets cached!
    return Array.isArray(this.getAllItems) ? this.getAllItems : this.getAllItems()
  }

  /**
   Subset of list items rendered on the DOM
   */
  //TODO: If endIndex < TOTAL_ITEMS we can display some dummy icons or if we don't know TOTAL_ITEMS we can display a loading row
  get visibleItems(): any[] {
    let visibleItems = []
    if (this.getItemPage) {
      let swrPage1: SWR<any[], number | boolean | void> = this.getItemPage(Math.max(this.currentPage - 1, 0), this.pageSize)
      let swrPage2: SWR<any[], number | boolean | void> = this.getItemPage(Math.max(this.currentPage, 1), this.pageSize)
      let swrPage3: SWR<any[], number | boolean | void> = this.getItemPage(Math.max(this.currentPage + 1, 2), this.pageSize)

      if (swrPage1.call?.promise || swrPage2.call?.promise || swrPage3.call?.promise) {
        this.loading = true
        new Promise(resolve => {
          (swrPage1.call?.promise || Promise.resolve()).then((max1: number | boolean | void) => {
            (swrPage2.call?.promise || Promise.resolve()).then((max2: number | boolean | void) => {
              (swrPage3.call?.promise || Promise.resolve()).then((max3: number | boolean | void) => {
                resolve(max3 || max2 || max1)
              })
            })
          })
        }).then(max => {
          let totalItems: number | null = null
          if (typeof max === 'number') {
            totalItems = Math.max(max, this.items.length)
          } else if (typeof max === 'boolean') {
            totalItems = (this.totalItems || 0) + Math.max((this.totalItems || 0) + 1, this.items.length + 1)
          } else if (this.totalItems === 0) {
            totalItems = Math.max(0, this.items.length)
          }
          if (totalItems !== null && this.totalItems !== totalItems) {
            this.totalItems = totalItems
            this.updateHeightEstimate()
          }
        }).finally(() => {
          this.loading = false
        })
      }

      visibleItems = (swrPage1.data || []).concat(swrPage2.data || []).concat(swrPage3.data || [])
    } else if (this.getAllItems) {
      visibleItems = this.items.slice(Math.max(this.currentPage - 1, 0) * this.pageSize, Math.max(this.currentPage + 1, 2) * this.pageSize)
    }

    this.$nextTick(() => {
      // If this getter has been called then the underlying data has changed, then MAYBE the rendered items will change,
      // which will be checked in update() at the next tick....
      this.update()
    })
    if (this.scrollDirection < 0) {
      return visibleItems.reverse()
    } else {
      return visibleItems
    }
  }

  /**
   Translate the spacer vertically to keep the scrollbar intact
   We only show N items at a time so the scrollbar would get affected if we dont translate
   */
  get spacerStyle() {
    if (this.scrollDirection >= 0) {
      return {
        willChange: "auto",
        transform: "translateY(" + this.translateY + "px)"
      }
    } else {
      return {
        bottom: this.translateY + "px",
        position: "absolute",
        willChange: "auto"
      }
    }
  }

  /**
   Set the height of the viewport
   For a list where all items are of equal height, height of the viewport = number of items x height of each item
   For a list where all items are of different height, it is the sum of height of each row
   */
  get viewportStyle() {
    return {
      minHeight: this.listHeight === null ? this.containerHeight : (Math.max(this.listHeight, this.root.scrollHeight) + "px"),
      position: "relative",
      willChange: "auto"
    }
  }

  /**
  Calculate the height of the root element
   */
  get rootStyle() {
    //TODO: Calculate containerHeight at first render?
    return {
      height: this.containerHeight || (window.innerHeight + "px"),
      overflow: "auto"
    }
  }

  //TODO: Test this
  @Watch('root.offsetHeight')
  watchRootHeight(newValue: number, oldValue: number) {
    this.$nextTick(() => {
      this.update()
    })
  }

  update() {
    let changed: boolean = Math.floor(this.items.length / this.pageSize) !== this.pagePositions.length
    if (!changed) {
      for (let item of this.items) {
        if (!this.rows.has(item.id)) {
          changed = true
          break
        }
      }
    }
    if (!changed) {
      for (let id of this.rows.keys()) {
        if (!this.items.find(item => item.id === id)) {
          changed = true
          break
        }
      }
    }

    if (changed) {
      const newPagePositions: Map<number, number> = new Map<number, number>()
      const averagePageHeight = Math.ceil(this.pagePositions[this.pagePositions.length - 1] / this.pagePositions.length)
      const minIndex = Math.max(this.currentPage - 1, 0) * this.pageSize
      const maxIndex = Math.min(Math.max(this.currentPage + 2, 3) * this.pageSize, this.items.length)
      const newRows: Map<any, HTMLElement> = new Map<any, HTMLElement>()
      for (let index = minIndex; index < maxIndex; index++) {
        // Given an item index, compute the page index
        // For example, any item index from 0 to 40 would translate to page index 0
        // Any item with index 50 to 99 would translate to page index 1
        const pageIndex = Math.floor(index / this.pageSize)
        // Get the scroll height and update the height of the item at index
        const element: HTMLElement | undefined = this.rows.get(this.items[index].id)
        const offsetHeight: number | undefined = element ? element.offsetHeight : this.rowHeights.get(this.items[index].id)
        if (offsetHeight !== undefined) {
          // Add the height of the row to the total height of all rows on the current page
          newPagePositions.set(pageIndex, (newPagePositions.get(pageIndex) || 0) + offsetHeight)
          if (element) newRows.set(this.items[index].id, element)
          this.rowHeights.set(this.items[index].id, offsetHeight)
        } else if (pageIndex > this.currentPage) { //TODO: Only if we have more items
          // We have reached the area where the items have not yet been rendered...
          // add one more page because when scrolling we need to know there is another page and we need to load more items.
          newPagePositions.set(pageIndex, averagePageHeight)
        }
      }

      //Clean up because keeping all the HTMLElements in the map forever crashes browsers!
      this.rows = newRows

      // Map is sorted by insertion order, no need to sort again.
      newPagePositions.forEach((value, index, map) => {
        this.pagePositions[index] = (index > 0 ? (this.pagePositions[index - 1] || 0) : 0) + value
      })

      const oldViewportHeight = this.viewport.scrollHeight

      this.updateHeightEstimate()

      this.$nextTick(() => {
        this.handleScroll() //Keep loading more until we got enough items.
        if (this.scrollDirection < 0) {
          this.$nextTick(() => {
            // If the list grows longer, the scroll position needs to be adjusted.
            const scrollDifference = Math.max(this.viewport.scrollHeight - oldViewportHeight, 0)
            this.root.scrollTop += scrollDifference
          })
        }
      })
    }
  }

  updateHeightEstimate() {
    // Total height of the viewport is an estimate based on the height of the previously loaded items.
    let newHeight = this.pagePositions[this.pagePositions.length - 1]
    if (this.totalItems && (this.scrollDirection >= 0 || this.initialTotalItems === 0)) {
      newHeight = Math.max(newHeight, Math.ceil(newHeight / this.pagePositions.length * this.totalItems / this.pageSize))
    } else if (this.totalItems) {
      newHeight = Math.max(newHeight, Math.ceil(newHeight / this.pagePositions.length * this.initialTotalItems / this.pageSize))
    }
    if (this.initialTotalItems === 0) {
      this.initialTotalItems = this.totalItems || 0
    }
    if (this.listHeight !== newHeight) {
      this.listHeight = newHeight
    }
  }

  handleScroll() { //TODO: Handle case where we overscrolled: Show loading...

    let pixelPosition = (this.pagePositions[this.currentPage - 2] || 0) - this.translateY
    if (this.scrollDirection >= 0) {
      pixelPosition += this.root.scrollTop + this.root.offsetHeight
    } else {
      pixelPosition += this.viewport.scrollHeight - this.root.scrollTop
    }

    const currentPage = this.getPageForPosition(this.pagePositions, pixelPosition, this.currentPage)
    const translateY = this.pagePositions[currentPage - 2] || 0

    // If we are on the last page and the page has changed or we know there are more items, load more
    if (currentPage >= this.pagePositions.length - 1 &&
        (currentPage !== this.currentPage || this.totalItems === null || this.items.length < this.totalItems)) {
      this.loadMore()
    }

    // Only modify start/end if the page has changed, in order to prevent unnecessary re-renders
    if (currentPage !== this.currentPage) {
      this.currentPage = currentPage
    }

    // Only modify translateY if the page has changed, in order to prevent unnecessary re-renders
    if (translateY !== this.translateY) {
      // Move the list down by the height of all pages that aren't rendered
      this.translateY = translateY
    }

    if (pixelPosition + this.translateY > this.pagePositions[this.pagePositions.length - 1]) {
      //TODO: We overscrolled... show loading...?
    }
  }

  /**
   Don't start in the middle like a regular binary search, instead start at the current page index.
   Usually we stay on the same page or we scroll from one page to the next, so most of the time we
   don't need to perform a search at all and if we have to search we know the starting direction.
   */
  getPageForPosition(arr: number[], pixelPosition: number, startingPoint: number) {
    let low: number = 0
    let high: number = Array.isArray(arr) ? arr.length - 1 : Object.keys(arr).length - 1
    let mid: number = startingPoint

    if (arr[mid] === pixelPosition || (arr[mid] > pixelPosition && (mid === 0 || arr[mid - 1] < pixelPosition))) {
      return mid
    } else if (arr[mid] < pixelPosition && (mid === high || arr[mid + 1] > pixelPosition)) {
      return mid + 1
    } else if (mid > 0 && arr[mid - 1] > pixelPosition && (mid === 1 || arr[mid - 2] < pixelPosition)) {
      return mid - 1
    } else if (arr[mid] > pixelPosition) {
      high = mid - 1
      mid = Math.max(high - Math.max(0.3 * (high - low), 1), low)
    } else {
      low = mid + 1
      mid = Math.min(low + Math.max(0.3 * (high - low), 1), high)
    }

    while (low < high) {
      if (arr[mid] === pixelPosition) {
        break
      } else if (arr[mid] > pixelPosition) {
        high = mid - 1
      } else {
        low = mid + 1
      }
      mid = Math.floor((high + low) / 2)
    }

    if (pixelPosition <= arr[mid]) {
      return mid
    } else {
      return mid + 1
    }
  }

  loadMore() {
    if (!this.loading && !!this.loadItems) {
      // Mark the loading status
      this.loading = true
      this.loadItems(this.pagePositions.length, this.pageSize).then((max: number | boolean | void) => {
        let totalItems: number | null = null
        if (typeof max === 'number') {
          totalItems = Math.max(max, this.items.length)
        } else if (typeof max === 'boolean') {
          totalItems = (this.totalItems || 0) + Math.max((this.totalItems || 0) + 1, this.items.length + 1)
        } else if (this.totalItems === 0) {
          totalItems = Math.max(0, this.items.length)
        }
        if (totalItems !== null && this.totalItems !== totalItems) {
          this.totalItems = totalItems
          this.updateHeightEstimate()
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }

  // This snippet is taken straight from https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
  // It will only work on browser so if you are using in an SSR environment, keep your eyes open
  doesBrowserSupportPassiveScroll() {
    let passiveSupported = false

    try {
      const options = {
        get passive() {
          // This function will be called when the browser attempts to access the passive property.
          passiveSupported = true
          return false
        }
      }

      //@ts-ignore
      window.addEventListener("test", null, options)
      //@ts-ignore
      window.removeEventListener("test", null, options)
    } catch (err) {
      passiveSupported = false
    }
    return passiveSupported
  }

  @Watch('getItemPage')
  watchGetItemPageFunction(oldFunction: any, newFunction: any) {

    this.reset()
  }

  @Watch('loadItems')
  watchLoadMoreFunction(oldFunction: any, newFunction: any) {

    this.reset()
    this.loadMore()
  }

  reset() {
    this.root.scrollTop = 0
    this.totalItems = null
    this.initialTotalItems = 0
    this.loading = false
    this.currentPage = -1
    this.pagePositions = []
    this.translateY = 0
    this.listHeight = null
  }

  mounted() {
    this.loadMore()
    // Check if browser supports passive scroll and add scroll event listener
    let options = this.doesBrowserSupportPassiveScroll() ? { passive: true } : false
    this.root.addEventListener("scroll", this.handleScroll, options)
  }

  destroyed() {
    this.root.removeEventListener("scroll", this.handleScroll)
  }
}
