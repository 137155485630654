

import {Options, Vue} from "vue-class-component";
import {Watch} from "vue-property-decorator";

@Options({
  props: {
    classes: Object,
    show: Boolean,
    size: String,
    fullscreen: Boolean
  },
  emits: [
      'show', 'hide'
  ]
})
export default class Modal extends Vue {

  show!: boolean
  size!: string
  classes!: any
  fullscreen!: boolean

  modalActive: boolean = false
  modalShow: boolean = false

  toggle() {
    const body = document.querySelector("body")
    this.modalActive = !this.modalActive
    if (body && this.modalActive) {
      body.classList.add("modal-open")
    } else if (body) {
      body.classList.remove("modal-open")
    }
    setTimeout(() => (this.modalShow = !this.modalShow), 10)
    if (this.modalActive) {
      this.$emit('show');
    } else {
      this.$emit('hide');
    }
  }

  @Watch('show')
  watchShowProp(newValue: boolean, oldValue: boolean) {
    if (newValue !== oldValue) {
      this.modalActive = oldValue
      this.modalShow = oldValue
      this.toggle()
    }
  }

  mounted() {
    if (this.show) {
      this.toggle()
    }
  }
}
