

import {Options, Vue} from "vue-class-component";

@Options({
  props: {
    modelValue: Boolean,
    checked: Boolean,
    type: String,
    disabled: Boolean,
    name: String
  }
})
export default class Checkbox extends Vue {

  modelValue!: boolean
  checked!: boolean
  type!: string
  disabled!: boolean
  name!: string

  toggle() {
    this.$emit('update:modelValue', !this.modelValue);
  }

}
