<template>
  <label class="animated-input" :class="animationClass">
    <span :class="labelClass">{{label}}</span>
    <input :disabled="disabled" :type="type" :name="name" :autocomplete="autocomplete === 'true' ? '' : 'new-password'" class="form form-control" v-model="internalValue" :placeholder="placeholder" @input="handleInput" @focus="handleFocus" @blur="handleBlur" ref="myinput"  @animationstart="checkAutofill"/>
  </label>

</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {Watch} from "vue-property-decorator";
import {ref} from "vue";

@Options({
    props: {
      label: String,
      placeholder: String,
      modelValue: String,
      inline: Boolean,
      autocomplete: String,
      error: String,
      type: String,
      disabled: Boolean,
      name: String,
      autofocus: Boolean
    }
  })
  export default class AnimatedInput extends Vue {
    label!: string;
    placeholder!: string;
    modelValue!: string;
    inline!: boolean;
    autocomplete!: string;
    error!: string;
    type!: string;
    disabled!: boolean;
    name!: string;
    autofocus!: boolean;

    focused: boolean = false;
    internalValue: string = this.modelValue || '';

    //@ts-ignore
    myinput: HTMLInputElement = ref<HTMLInputElement | null>(null);

    get animationClass (): string {
      if (this.focused || (this.internalValue && this.internalValue !== '')) {
        return 'ai-filled' + this.errorClass;
      } else {
        return '' + this.errorClass;
      }
    }

    get labelClass (): string {
      return (this.inline ? "input-label-inline" : "input-label");
    }

    get errorClass(): string {
      if (this.error) {
        if (this.focused) {
          return ' border-danger';
        } else {
          return ' border-danger danger';
        }
      } else {
        return '';
      }
    }

    handleInput () {
      this.$emit('update:modelValue', this.internalValue);
    }

    handleFocus () {
      this.focused = true;
      this.$emit('focus');
    }

    handleBlur () {
      this.focused = false;
      this.$emit('blur');
    }

    checkAutofill(e : AnimationEvent) {
      if (e.animationName === "onAutoFillStart") this.$emit("autofill", true);
      else if (e.animationName === "onAutoFillCancel") this.$emit("autofill", false);
    }

    @Watch('modelValue')
    onValueChanged (val: string, oldVal: string) {
      this.internalValue = val;
    }

    //Do things when we're in the dom:
    mounted () {
      if (this.autofocus) {
        this.myinput.focus();
      }
    }
  };
</script>

<style lang="scss" scoped>
 .input-group-input > .animated-input {
    margin-bottom: 0;
  }
</style>
