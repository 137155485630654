
import {Options, Vue} from 'vue-class-component'
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import InfiniteList from "@/components/common/InfiniteList.vue";
import UserListItem from "@/components/UserListItem.vue";
import {userServiceApi} from "@/api/UserServiceApi";
import User from "@/model/User";
import {settingsServiceApi} from "@/api/SettingsServiceApi";
import SWR from "@/api/SWR";
import Setting from "@/model/Setting";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import {useToast} from "vue-toastification";
import {rpcClient} from "@/api/Vue3WebsocketClient";
import SortAndFilterUtil from "@/util/SortAndFilterUtil";

@Options({
  components: {
    InfiniteList, AnimatedInput, LoadingButton, UserListItem
  }
})
export default class Users extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  pageSize: number = 100
  swr: SWR<Setting[], string[]> | null = null
  roleOptions: { value: string, label: string }[] = [
    { value: 'USER', label: this.i18n.$gettext('User') },
    { value: 'AUDIT', label: this.i18n.$gettext('Auditor') },
    { value: 'ADMIN', label: this.i18n.$gettext('Administrator') }
  ]

  get users(): User[] {
    return userServiceApi.getUsers([ 'userName' ], false).data || []
  }

  get disablePermissionChange(): boolean {
    let setting: Setting | undefined = settingsServiceApi.settings.find(setting => setting.key == 'DISABLE_PERMISSION_MANAGEMENT')
    return Boolean(setting && setting.value === 'true');
  }

  async saveUserRoles(): Promise<void> {
    const admin: User | undefined = this.users.find(u => u.roles && u.roles.indexOf('ADMIN') >= 0)
    if (admin) {
      for (let user of this.users) {
        const thisUser = user
        if (thisUser.userName !== null && thisUser.roles !== null) {
          await userServiceApi._changeUserRoles(thisUser.userName, thisUser.roles).catch(e => {
            this.toast.error(this.i18n.$gettext('Failed to save users.'))
          })
        }
      }
      if (rpcClient.session.user && rpcClient.session.user.userName) {
        const changedUser: User | undefined = userServiceApi.getUser(rpcClient.session.user.userName)
        if (changedUser && !SortAndFilterUtil.arrayEquals(changedUser.roles, rpcClient.session.user.roles)) {
          rpcClient.logout()
        }
      }
    } else {
      this.toast.error(this.i18n.$gettext('You need at least one admin.'))
    }
  }
}
