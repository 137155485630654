
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/Email"
import Setting from "@/model/Setting";
import {settingsServiceApi} from "@/api/SettingsServiceApi";
import {ref} from "vue"
import {Watch} from "vue-property-decorator"

@Options({
  props: {
    email: Email,
    state: Object,
    mode: String
  }
})
export default class DetailBody extends Vue {

  settingsServiceApi = settingsServiceApi

  email!: Email
  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }
  mode: string = 'rich'
  emailIframeLoaded: boolean = false
  imagesAllowed: boolean | null = null

  //@ts-ignore
  emailIframe: HTMLIFrameElement | null = ref<HTMLIFrameElement | null>(null)
  htmlFrames: HTMLIFrameElement[] = []

  @Watch('email.digest')
  watchEmailDigest(oldDigest: string, newDigest: string) {
    if (oldDigest !== newDigest) {
      this.emailIframeLoaded = false
    }
  }

  get allowImages(): boolean {
    if (this.imagesAllowed === null) {
      return this.settingsServiceApi.settings.find(setting => setting.key == 'SHOW_IMAGES')?.value === 'true'
    } else {
      return this.imagesAllowed
    }
  }

  downloadAttachment(attachment: string) {
    if (this.email && this.email.digest) {
      window.open('/emails/' + encodeURIComponent(this.email.digest) + '/attachments/' + encodeURIComponent(attachment), '_blank')
    }
  }

  get separateRecipients(): boolean {
    let setting: Setting | undefined = settingsServiceApi.settings.find(setting => setting.key == 'DETAIL_ADDRESS_DISPLAY')
    if (setting) {
      return setting.value === 'separate'
    } else {
      return false
    }
  }

  get allRecipients() {
    return this.email ? (this.email.to || []).concat(this.email.cc || []).concat(this.email.bcc || []) : []
  }

  adjustIframeHeight(): void {
    try {
      const scrollHeight = this.emailIframe?.contentWindow?.document?.body?.scrollHeight
      if (scrollHeight && this.emailIframe) {
        let newHeight: number = scrollHeight + 50 // margin
        this.emailIframe.style.height = newHeight + 'px'
        this.emailIframeLoaded = true
      }
    } catch (ignore) {
    }
  }

  adjustIframeHeight2(): void {
    for (let iframe of this.htmlFrames) {
      try {
        const scrollHeight = iframe.contentWindow?.document?.body?.scrollHeight
        if (scrollHeight && this.emailIframe) {
          let newHeight: number = scrollHeight + 50 // margin
          this.emailIframe.style.height = newHeight + 'px'
        }
      } catch (ignore) {
      }
    }
  }

  toggleImages() {
    this.imagesAllowed = !this.allowImages
  }

  print() {
    if (!this.email?.digest) return
    const content: HTMLElement | undefined = document.querySelector('#detailbody') as HTMLElement
    if (content) {
      let height: number = content.clientHeight
      let width: number = content.clientWidth
      if (this.emailIframe?.contentWindow) {
        height = this.emailIframe.contentWindow.document.body.scrollHeight
        width = this.emailIframe.contentWindow.document.body.scrollWidth
      }
      const printWin: Window | null = window.open('', 'PRINT', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=' + (window.screen?.width || width) + 'px,height=' + (window.screen?.height || height) + 'px')
      if (printWin) {
        printWin.document.write('<html><head>' + document.head.innerHTML  + '</head><body><div id="printFrames" style="page-break-inside: avoid; position: relative; width: 100%">')
        printWin.document.write('<iframe id="printFrame" style="page-break-inside: avoid; border: none; box-shadow: none; width: 100%" src="' + window.location.origin + '/emails/' + encodeURIComponent(this.email.digest) + '/html/?allowImages=true&header=true" sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox" />')
        printWin.document.write('</div></body></html>')

        printWin.document.close() // necessary for IE >= 10
        printWin.focus() // necessary for IE >= 10*/

        this.printFrame(printWin, width, height)
      }
    }
  }

  printFrame(printWin: Window, width: number, height: number) {
    const printFrame: HTMLElement | null = printWin.document.getElementById('printFrame')
    if (printFrame) {
      printFrame.style.height = '100%'
      printFrame.style.width = '100%'
      printFrame.style.minHeight = height + 'px'
      printFrame.style.minWidth = width + 'px'
    }
    //@ts-ignore
    if (printFrame && printFrame.attachEvent) {
      const frame: HTMLIFrameElement = printFrame as HTMLIFrameElement
      //@ts-ignore
      printFrame.attachEvent('onload', function() {
        if (frame.contentWindow) {
          frame.contentWindow.document.body.style.overflow = 'hidden'
        }
        printWin.print()
        printWin.close()
      })
    } else if (printFrame) {
      const frame: HTMLIFrameElement = printFrame as HTMLIFrameElement
      printFrame.onload = function() {
        if (frame.contentWindow) {
          frame.contentWindow.document.body.style.overflow = 'hidden'
        }
        printWin.print()
        printWin.close()
      }
    } else {
      printWin.setTimeout(() => {
        this.printFrame(printWin, width, height)
      })
    }
  }
}
