<template>
  <div class="container-fluid pr-4 pt-4 pb-4 pl-0 h-100">
    <div class="row h-100" style="overflow: auto">
      <div class="col-12 col-xl-auto p-0">
        <div class="d-flex bg-white ml-2 p-4 flex-row flex-xl-column min-vh-xl-60">
          <button class="btn btn-outline-primary btn-raised text-left" type="button" @click="state.emailDigest = null; mode = 'search'; state.showSearch = true">
            <i class="fas fa-search"></i><span v-if="width > 1279">&emsp;<translate>Search</translate></span>
          </button>
          <button v-if="isAudit" class="btn btn-outline-primary btn-raised ml-3 ml-xl-0 mt-xl-3 text-left" type="button" @click="state.emailDigest = null; mode = 'audit'">
            <i class="fas fa-history"></i><span v-if="width > 1279">&emsp;<translate>Audit Log</translate></span>
          </button>
          <button v-if="isAdmin" class="btn btn-outline-primary btn-raised ml-3 ml-xl-0 mt-xl-3 text-left" type="button" @click="state.emailDigest = null; mode = 'settings'">
            <i class="fas fa-tools"></i><span v-if="width > 1279">&emsp;<translate>Settings</translate></span>
          </button>
          <button v-if="isAdmin" class="btn btn-outline-primary btn-raised ml-3 ml-xl-0 mt-xl-3 text-left" type="button" @click="state.emailDigest = null; mode = 'users'">
            <i class="fas fa-users"></i><span v-if="width > 1279">&emsp;<translate>Users</translate></span>
          </button>
          <div class="mt-xl-3"></div>
          <button class="btn btn-outline-danger btn-raised ml-3 ml-xl-0 mt-xl-auto text-left" type="button" @click="rpcClient.logout">
            <i class="fas fa-lock"></i><span v-if="width > 1279">&emsp;<translate>Logout</translate></span>
          </button>
        </div>
      </div>

      <div :style="(state.emailDigest || mode !== 'search') ? 'max-width: 0' : ''" style="overflow: auto" class="d-none d-xl-inline-block p-0 col-xl-4">
        <SearchMask :state="state" class="min-vh-xl-60"></SearchMask>
      </div>
      <div v-if="!state.emailDigest && mode === 'search'" class="d-xl-none col-12 p-0" :class="{ 'd-none': !state.showSearch }">
        <SearchMask :state="state"></SearchMask>
      </div>

      <div :class="mode === 'search' ? (state.emailDigest ? 'col-xl-5' : 'col-xl-6') : 'col'" class="mt-3 mt-xl-0 p-0 h-100">
        <ResultList v-if="mode === 'search'" :state="state"></ResultList>
        <Audit v-if="mode === 'audit'" style="max-width: 1280px"></Audit>
        <Settings v-if="mode === 'settings'" style="max-width: 1280px"></Settings>
        <Users v-if="mode === 'users'" style="max-width: 1280px"></Users>
      </div>

      <div id="detailCol" ref="detailCol" class="col p-0 h-100 d-none d-xl-inline-block" :style="(state.emailDigest && mode === 'search') ? 'max-width: 1000px' : 'max-width: 0'" style="overflow: hidden">
        <DetailView ref="detailView" :state="state"></DetailView>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import SearchMask from './SearchMask.vue'
import ResultList from './ResultList.vue'
import DetailView from './DetailView.vue'
import Audit from "@/components/Audit.vue";
import Settings from "@/components/Settings.vue";
import {rpcClient} from "@/api/Vue3WebsocketClient";
import Modal from "@/components/common/Modal.vue";
import Users from "@/components/Users.vue";
import {reactive, ref} from "vue";

@Options({
  components: {
    ResultList, DetailView, SearchMask, Settings, Audit, Modal, Users
  }
})
export default class SearchInterface extends Vue {

  rpcClient = rpcClient

  //@ts-ignore
  detailCol: HTMLElement | null = ref<HTMLElement | null>(null)
  //@ts-ignore
  detailView: DetailView | null = ref<DetailView | null>(null)

  mode: string = 'search'
  state: { queryId: string | null, showSearch: boolean, emailDigest: string | null, unsavedSettings: boolean } = reactive({
    queryId: null,
    showSearch: true,
    emailDigest: null,
    unsavedSettings: false
  })

  get isAdmin() {
    return rpcClient.session.user && rpcClient.session.user.roles && (rpcClient.session.user.roles.indexOf('ADMIN') >= 0)
  }

  get isAudit() {
    return rpcClient.session.user && rpcClient.session.user.roles && (rpcClient.session.user.roles.indexOf('AUDIT') >= 0)
  }

  get width() {
    return window.innerWidth
  }

  mounted() {
    if (window.location.pathname.length > 20) {
      this.state.emailDigest = window.location.pathname.replace('/', '')
    }
    this.$nextTick(() => {
      this.detailCol?.addEventListener('transitionstart', (event) => {
        if (event.target === this.detailCol) {
          this.detailView?.detailButtons?.fadeIn()
        }
      })
    })
  }
}
</script>

<style lang="scss">
.row [class*='col-'], #detailCol {
  transition: flex 0.5s ease-in-out, max-width .5s ease-in-out, max-height .5s ease-in-out;
}

@media screen and (min-width: 1200px) {
  .min-vh-xl-60 {
    min-height: 60vh;
  }
}
</style>
