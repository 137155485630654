import alphaSort, {StringComparator} from 'alpha-sort';

const sortFunc: StringComparator = alphaSort({natural: true, caseInsensitive: true})

export default class SortAndFilterUtil {

    /* Sorts the array in place */
    static sort(items: any[], sorts?: string[] | string): void {
        if (sorts && !Array.isArray(sorts)) {
            sorts = [ sorts as string ]
        }
        if (sorts) {
            for (let sort of sorts) {
                const sortDef = sort.split(':')
                const prop: string = sortDef[0]
                const direction: number = (sortDef.length < 2 || sortDef[1] == 'asc') ? 1 : -1
                items.sort((a: any, b: any) => {
                    let aVal: any | null | undefined = Object(a)[prop]
                    let bVal: any | null | undefined = Object(b)[prop]
                    if (aVal === bVal || (!aVal && !bVal)) {
                        return 0
                    } else if (aVal === null || aVal === undefined) {
                        return -direction
                    } else if (bVal === null || bVal === undefined) {
                        return direction
                    } else if (typeof aVal === "string" && typeof bVal === "string") {
                        return direction * sortFunc(aVal, bVal)
                    } else {
                        return aVal > bVal ? direction : -direction
                    }
                })
            }
        }
    }

    /* Returns a new filtered array */
    static filter(items: any[], filter: any): any[] {
        return items.filter(item => {
            for (let key in filter) {
                if (filter.hasOwnProperty(key) && filter[key] !== null) {
                    if (Array.isArray(filter[key]) && Array.isArray(Object(item)[key]) && !this.arrayEquals(filter[key], Object(item)[key])) {
                        return false
                    } else if (Array.isArray(filter[key]) && filter[key].indexOf(Object(item)[key]) < 0) {
                        return false
                    } else if (!Array.isArray(filter[key]) && Object(item)[key] !== filter[key]) {
                        return false
                    }
                }
            }
            return true
        })
    }

    static arrayEquals(a: any[] | null | undefined, b: any[] | null | undefined): boolean {
        if (a === b) return true
        if (a === null || b === null || a === undefined || b === undefined) return false
        if (a.length !== b.length) return false

        const aSorted = [...a].sort()
        const bSorted = [...b].sort()

        for (let i = 0; i < aSorted.length; ++i) {
            if (aSorted[i] !== bSorted[i]) return false
        }

        return true
    }
}
