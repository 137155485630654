import { reactive } from 'vue'
import AuditEntry from '@/model/AuditEntry';

class GeneratedAuditEntryStore {

    state = reactive({
        auditEntrys: new Array<AuditEntry>()
    })

    addOrReplaceAuditEntry(newAuditEntry: AuditEntry) {
        let oldAuditEntry = this.state.auditEntrys.find((value: AuditEntry) => {
            return value.id === newAuditEntry.id
        })

        if (oldAuditEntry !== undefined) {
            this.state.auditEntrys = this.state.auditEntrys.map((value: AuditEntry) => {
                if (value.id === newAuditEntry.id) {
                    return newAuditEntry
                } else {
                    return value
                }
            })
        } else {
            this.state.auditEntrys.push(newAuditEntry)
        }
    }
    
    addOrReplaceAuditEntrys(newAuditEntrys: AuditEntry[]) {
        let newState: AuditEntry[] = this.state.auditEntrys.map((oldAuditEntry: AuditEntry) => {
            let newAuditEntryIndex: number = -1
            for (let i = 0; i < newAuditEntrys.length; i++) {
                if (newAuditEntrys[i].id === oldAuditEntry.id) {
                    newAuditEntryIndex = i
                    break
                }
            }
            let auditEntry: AuditEntry
            if (newAuditEntryIndex !== -1) {
                auditEntry = newAuditEntrys[newAuditEntryIndex]
                newAuditEntrys.splice(newAuditEntryIndex, 1)
            } else {
                auditEntry = oldAuditEntry
            }
            return auditEntry
        })
        for (let i = 0; i < newAuditEntrys.length; i++) {
            newState.push(newAuditEntrys[i])
        }
        this.state.auditEntrys = newState
    }

    replaceAuditEntrys(auditEntrys: AuditEntry[]) {
        this.state.auditEntrys = auditEntrys
    }

    removeAuditEntry(id: number) {
        let toDelete = this.state.auditEntrys.find((auditEntry: AuditEntry) => {
            return auditEntry.id === id
        })

        if (toDelete !== undefined) {
            let index = this.state.auditEntrys.indexOf(toDelete)
            if (index > -1) {
                this.state.auditEntrys.splice(index, 1)
            }
        }
    }
}

export const auditEntryStore = new GeneratedAuditEntryStore()