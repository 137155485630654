
import {Options, Vue} from 'vue-class-component'
import SearchInterface from './components/SearchInterface.vue'
import LoginForm from './components/LoginForm.vue'
import {rpcClient} from '@/api/Vue3WebsocketClient'
import {settingsServiceApi} from "@/api/SettingsServiceApi";

@Options({
  components: {
    SearchInterface, LoginForm
  }
})
export default class App extends Vue {
  rpcClient = rpcClient
  settingsServiceApi = settingsServiceApi

  created() {
    this.settingsServiceApi.settings
  }
}
