<template>
  <div class="h-100 mr-xl-2 ml-xl-2">
    <div class="d-flex flex-column bg-white z-1 pl-4 pr-4 pt-2 pt-xl-3 pb-2 sort-bar">
      <div class="row">
        <div class="col-auto d-flex">
          <p class="mb-2 mb-xl-1 d-block d-xl-inline mr-xl-6 align-self-center text-right text-xl-left"><span v-if="total !== null"><translate>Emails found:</translate>&nbsp;{{ total }}</span><span class="d-xl-none"><br v-if="total !== null"><translate>Sort by:</translate></span></p>
          <p class="mb-2 mb-xl-1 text-right d-none d-xl-inline align-self-center"><translate>Sort by:</translate></p>
        </div>
        <div class="col d-flex">
          <MultiSelect class="align-self-center" v-model="sortBy" :options="sortOptions" :canDeselect="false"/>
        </div>
      </div>
    </div>
    <div class="pt-2 result-list">
      <div class="d-flex flex-column bg-white z-1 h-100">
        <InfiniteList v-if="state.queryId" :getAllItems="allItems" :getItemPage="itemPage" :pageSize="pageSize" v-slot="{ item }">
          <ResultListItem :email="item" :selected="this.state.emailDigest === item.digest" :separateRecipients="separateRecipients" @click="selectEmail(item.digest)"></ResultListItem>
          <hr class="m-0">
        </InfiniteList>
        <div v-else class="p-4">
          <p class="text-dark"><translate>Please enter a search term...</translate></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mailServiceApi } from "@/api/MailServiceApi"
import Email from "@/model/Email";
import SWR from "@/api/SWR";
import ResultListItem from "@/components/ResultListItem.vue"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import {useToast} from "vue-toastification";
import MultiSelect from "@/components/common/MultiSelect.vue";
import Setting from "@/model/Setting";
import {settingsServiceApi} from "@/api/SettingsServiceApi";

@Options({
  components: {
    InfiniteList, MultiSelect, ResultListItem
  },
  props: {
    state: Object
  }
})
export default class ResultList extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }

  pageSize: number = 50
  sortBy: string = 'date:desc'
  sortOptions: { value: string, label: string }[] = [
    { value: 'score:desc', label: this.i18n.$gettext('Accuracy') },
    { value: 'date:asc', label: this.i18n.$gettext('Date ascending') },
    { value: 'date:desc', label: this.i18n.$gettext('Date descending') },
    { value: 'sender:asc', label: this.i18n.$gettext('Sender ascending') },
    { value: 'sender:desc', label: this.i18n.$gettext('Sender descending') }
  ]

  get total(): number | null {
    return this.state.queryId ? mailServiceApi.state.total : null
  }

  get allItems(): Email[] {
    const sortBy = this.sortBy //Access sortBy in order to make it reactive!
    return mailServiceApi.getEmails(sortBy)
  }

  get itemPage(): ((pageIndex: number, pageSize: number) => SWR<Email[], number | boolean | void>) | null {
    if (this.state.queryId) {
      const queryId = this.state.queryId
      const sortBy = this.sortBy //Access sortBy in order to make it reactive!
      return (pageIndex: number, pageSize: number) => {
        let swr: SWR<Email[], number | boolean | void> = mailServiceApi.queryEmails(queryId, sortBy, pageIndex, pageSize, false)
        if (swr.call?.promise) swr.call?.promise.then((max: number | boolean | void) => {
          if (typeof max === 'number') mailServiceApi.state.total = max
          return max
        }).catch(e => {
          this.toast.error(this.i18n.$gettext('Failed to load results.'))
        })
        return swr
      }
    } else {
      return null
    }
  }

  get separateRecipients(): boolean {
    let setting: Setting | undefined = settingsServiceApi.settings.find(setting => setting.key == 'RESULT_ADDRESS_DISPLAY')
    if (setting) {
      return setting.value === 'separate'
    } else {
      return false
    }
  }

  selectEmail(digest: string) {
    this.state.emailDigest = digest
  }
}
</script>

<style lang="scss">
.sort-bar {
  height: 60px
}
.result-list {
  height: calc(100% - 180px)
}
@media screen and (min-width: 1200px) {
  .sort-bar {
    height: 80px
  }
  .result-list {
    height: calc(100% - 80px)
  }
}
</style>
