import {createApp} from 'vue'
import App from './App.vue'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import { createGettext } from "@jshmrtn/vue3-gettext";
import translations from './locale/generated/translations.json';

const gettext = createGettext({
    availableLanguages: {
        en_US: 'American English',
        de_DE: 'Deutsch'
    },
    defaultLanguage: 'de_DE',
    translations
});

// @ts-ignore
const app = createApp(App)
// @ts-ignore
//app.component('v-select', vSelect);

app.use(gettext)
app.use(Toast, {})
app.mount('#app')


