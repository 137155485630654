
import {Options, Vue} from 'vue-class-component'
import Email from "@/model/Email"
import {mailServiceApi} from "@/api/MailServiceApi";
import LoadingButton from "@/components/common/LoadingButton.vue";
import Modal from "@/components/common/Modal.vue";
import {ref} from "vue";
import {useToast} from "vue-toastification";
import {Language, useGettext} from "@jshmrtn/vue3-gettext";
import {rpcClient} from "@/api/Vue3WebsocketClient";
import {queryServiceApi} from "@/api/QueryServiceApi";
import Query from "@/model/Query";
import {windowWatcher} from "@/util/WindowWatcher"
import {settingsServiceApi} from "@/api/SettingsServiceApi"

@Options({
  components: {
    LoadingButton, Modal
  },
  props: {
    email: Email,
    state: Object
  },
  emits: [ 'delete', 'email:print', 'email:toggleImages' ]
})
export default class DetailButtons extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  windowWatcher = windowWatcher
  settingsServiceApi = settingsServiceApi

  //@ts-ignore
  deleteModal: Modal = ref<Modal | null>(null)
  //@ts-ignore
  buttonRow: HTMLElement = ref<HTMLElement | null>(null)

  email!: Email
  state!: { queryId: string | null, showSearch: boolean, emailDigest: string | null }

  mode: string = 'rich'
  deleteComment: string = ''
  imagesAllowed: boolean | null = null

  get allowImages(): boolean {
    if (this.imagesAllowed === null) {
      return this.settingsServiceApi.settings.find(setting => setting.key == 'SHOW_IMAGES')?.value === 'true'
    } else {
      return this.imagesAllowed
    }
  }

  toggleSourceView() {
    if (this.mode === 'rich') {
      this.mode = 'source'
    } else if (this.mode === 'source') {
      this.mode = 'plain'
    } else {
      this.mode = 'rich'
    }
    this.$emit('update:modelValue', this.mode)
  }

  downloadEmail() {
    if (this.email && this.email.digest) {
      window.open('/emails/' + encodeURIComponent(this.email.digest) + '.eml', '_blank')
    }
  }

  permaLink() {
    if (this.email && this.email.digest) {
      const el = document.createElement('textarea')
      el.value = window.location.protocol + '//' + window.location.host + '/' + this.email.digest
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.toast.success(this.i18n.$gettext('Link copied to clipboard.'))
    }
  }

  deleteEmail(): Promise<void> {
    this.$emit('delete')
    if (this.email && this.email.digest) {
      return mailServiceApi._deleteEmail(this.email.digest, this.deleteComment).finally(() => {
        this.deleteModal.toggle()
      })
    } else {
      return Promise.resolve()
    }
  }

  showThread() {
    if (!this.state.queryId || queryServiceApi.getQuery(this.state.queryId)?.threadId !== this.email.threadId) {
      let query: Query = new Query()
      query.threadId = this.email.threadId
      queryServiceApi._startQuery(query).then((queryId: string) => {
        if (this.windowWatcher.width < 1200) this.state.emailDigest = null //Close the modal on mobile
        mailServiceApi.resetState()
        Object.assign(this.state, { queryId: queryId, showSearch: false })
      }).catch(e => {
        this.toast.error(this.i18n.$gettext('Failed to load email thread.'))
      })
    } else if (this.windowWatcher.width < 1200) {
      this.state.emailDigest = null //Close the modal on mobile
    }
  }

  toggleImages() {
    this.imagesAllowed = !this.allowImages
    this.$emit('email:toggleImages')
  }

  fadeIn() {
    this.buttonRow.style.transition = ''
    this.buttonRow.style.opacity = '0';
    setTimeout(() => {
      this.buttonRow.style.transition = 'opacity .2s ease-in'
      this.buttonRow.style.opacity = '1';
    }, 200)
  }

  get isAudit() {
    return rpcClient.session.user && rpcClient.session.user.roles && (rpcClient.session.user.roles.indexOf('AUDIT') >= 0)
  }
}
